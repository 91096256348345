// components/chakra/NavbarWithCallToAction/QuoteModal.tsx
import React from 'react';
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalBody,
    ModalFooter,
    Button,
    VStack,
    Heading,
    Text,
    Center,
} from '@chakra-ui/react';
import { FaPhone } from 'react-icons/fa';
import { Logo } from '../components/Logo';



export const QuoteModal = ({ isOpen, onClose }: { isOpen: boolean, onClose: () => void }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent bg="#d0c9bd">
                <ModalHeader p={0}>
                    <Center pt={6}>
                        <Logo />
                    </Center>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <VStack spacing={4}>
                        <Heading size="lg" textAlign="center">Get a Free Quote</Heading>
                        <Text textAlign="center">
                            For a quick chat and a free quote, please call us now on:
                        </Text>
                        <Button
                            leftIcon={<FaPhone />}
                            colorScheme="gray"
                            size="lg"
                            width="full"
                            onClick={() => window.location.href = 'tel:0488041641'}
                        >
                            0488 041 641
                        </Button>
                    </VStack>
                </ModalBody>
                <ModalFooter>
                    <Button colorScheme="gray" mr={3} onClick={onClose} variant="secondary">
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};