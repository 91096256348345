// src/pages/Marketplace.tsx
import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Heading,
    Text,
    SimpleGrid,
    Image,
    Button,
    VStack,
    HStack,
    Tag,
    useToast,
    Input,
    InputGroup,
    InputLeftElement,
    Flex,
    Link,
    Divider,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useAuth } from '../contexts/AuthContext';
import { getApiUrl } from '../utils/helpers';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { useInviteCode } from '../hooks/useInviteCode';
import BackendProducts from '../components/BackendProducts';

// Updated product type
interface Product {
    id: number;
    name: string;
    price: number;
    category: string;
    image: string;
    url: string;
}

// Updated product list with the relationships course and URLs
const dummyProducts: Product[] = [
    // { id: 1, name: 'Eco-Friendly Water Bottle', price: 2500, category: 'Sustainability', image: '/product_images/waterbottle.png', url: '/product/1' },
    // { id: 2, name: 'Organic Cotton T-Shirt', price: 3000, category: 'Fashion', image: '/product_images/cotton_shirt.png', url: '/product/2' },
    // { id: 3, name: 'Solar-Powered Charger', price: 5000, category: 'Tech', image: '/product_images/solar_charger.png', url: '/product/3' },
    { id: 4, name: 'Simulated Group Buy 55.74 acres in Canungra', price: 40000, category: 'Land', image: '/product_images/canungra.png', url: '/LandPurchaseCanungra' },
    // { id: 4, name: 'Simulated Group Buy 55.74 acres in Canungra', price: 29929, category: 'Land', image: '/product_images/recycled_plastic_backpack.png', url: '/LandPurchaseCanungra' },

    { id: 5, name: '1080 Resilience and Self-Governance', price: 33, category: 'Onlinge Course', image: '/product_images/1080.png', url: '/resilience' },
    {
        id: 65,
        name: 'Transform Your Relationship Course',
        price: 400, // 690 AUD in sats
        category: 'Relationships',
        image: '/product_images/relationship_course.png', // You'll need to add this image
        url: '/relationships'
    },
    { id: 44, name: 'BrisbaneGroupBeefBuy', price: 125, category: 'Beef', image: '/product_images/12BeefBox.png', url: '/brisbanegroupbeefbuy' },
];

export const Marketplace = () => {
    const [products, setProducts] = useState(dummyProducts);
    const [searchTerm, setSearchTerm] = useState('');
    const { user } = useAuth();
    const toast = useToast();
    const apiUrl = getApiUrl();
    useInviteCode(); // Add this line to handle invite codes
    const location = useLocation();
    const [inviteCode, setInviteCode] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('inviteCode');
        if (code) {
            setInviteCode(code);
            navigate(location.pathname, { replace: true });
        }
    }, [location, navigate]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
        // Filter products based on search term
        const filteredProducts = dummyProducts.filter(product =>
            product.name.toLowerCase().includes(event.target.value.toLowerCase())
        );
        setProducts(filteredProducts);
    };

    const handleProductClick = (product: Product) => {
        // Navigate to the product URL
        navigate(product.url);
    };

    return (
        <Flex direction="column" minHeight="100vh">
            <NavbarWithCallToAction />

            <Container maxW="container.xl" py={8} flex={1}>
                <VStack spacing={8} align="stretch">
                    <Box textAlign="center">
                        <Heading as="h1" size="2xl" mb={4}>
                            Lightning-Powered Marketplace
                        </Heading>
                        <Text fontSize="xl" mb={6}>
                            Browse products, share, and earn instant rewards with Lightning Network microtransactions.
                        </Text>
                    </Box>

                    <InputGroup>
                        <InputLeftElement pointerEvents="none">
                            <SearchIcon color="gray.300" />
                        </InputLeftElement>
                        <Input
                            type="text"
                            placeholder="Search products..."
                            value={searchTerm}
                            onChange={handleSearch}
                        />
                    </InputGroup>

                    <Heading as="h2" size="xl" mb={4}>
                        Featured Products
                    </Heading>

                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
                        {products.map(product => (
                            <Box key={product.id} borderWidth={1} borderRadius="lg" overflow="hidden">
                                <Link as={RouterLink} to={product.url}>
                                    <Image src={product.image} alt={product.name} />
                                    <Box p={6}>
                                        <Box flexDirection="column" alignItems="baseline">
                                            <Tag borderRadius="full" px={2} colorScheme="teal">
                                                {product.category}
                                            </Tag>
                                        </Box>
                                        <Heading as="h3" size="md" mt={2}>
                                            {product.name}
                                        </Heading>
                                        <Text fontWeight="bold" mt={2}>
                                            {/* {product.price} sats (≈ AUD ${(product.price * 0.0005).toFixed(2)}) */}
                                            {product.price} sats (≈ AUD ${product.price.toFixed(2)})
                                        </Text>
                                    </Box>
                                </Link>
                                <Box p={6} pt={0}>
                                    <Button
                                        colorScheme="blue"
                                        mt={4}
                                        onClick={() => handleProductClick(product)}
                                        width="100%"
                                    >
                                        View
                                    </Button>
                                </Box>
                            </Box>
                        ))}
                    </SimpleGrid>




                    <Divider my={8} />

                    <Heading as="h2" size="xl" mb={4}>
                        Offers from the Community
                    </Heading>

                    <BackendProducts />
                </VStack>
            </Container>

            <FooterWithFourColumns />
        </Flex>
    );
};

export default Marketplace;