import { HTMLChakraProps, chakra } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react';

export const Logo = (props: HTMLChakraProps<'img'>) => (
  // Use the logo from /logo.png
  <Image
    src="/logo.png"
    alt="Logo"
    height="80px"
    width="auto"
    {...props}
  />
);

export default Logo;
