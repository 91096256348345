export const links = [
  {
    // title: 'Company',
    links: [
      { label: 'About us', href: '/about' },
      // { label: 'Our story', href: '/earn' },
      // { label: 'Careers', href: '#' },
      // { label: 'Press', href: '#' },
      // { label: 'FAQs', href: '/earn' },
    ],
  },
  {
    // title: 'Product',
    links: [
      { label: 'Deck Maintence', href: '/decks' },
      // { label: 'Pricing', href: '#' },
      { label: 'Specialty Builds', href: '/specialtybuilds' },
      // { label: 'Integrations', href: '#' },
      // { label: 'SAML SSO', href: '#' },
    ],
  },
  {
    title: 'Resources',
    links: [
      // { label: 'News', href: '/news' },
      // { label: 'Partnerships', href: '#' },
      // { label: 'Case studies', href: '#' },
      // { label: 'Media Assets', href: '#' },
    ],
  },
  {
    title: 'Legal',
    links: [
      // { label: 'Terms of Service', href: '#' },
      // { label: 'Privacy Policy', href: '#' },
      // { label: 'Offer terms', href: '#' },
      // { label: 'License', href: '#' },
    ],
  },
]
