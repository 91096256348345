// src/pages/News.tsx
import React, { useState, useEffect } from 'react';
import {
    Box,
    Heading,
    Text,
    Container,
    VStack,
    AspectRatio,
    Link,
    SimpleGrid,
    useColorModeValue,
    Button,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Center,
    ListItem,
    UnorderedList,
} from '@chakra-ui/react';
import { QRCodeSVG } from 'qrcode.react';
import { useAuth } from '../contexts/AuthContext';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useLocation, useNavigate } from 'react-router-dom';
import { useInviteCode } from '../hooks/useInviteCode';

interface NewsItem {
    title: string;
    description: string;
    videoUrl?: string;
    linkUrl?: string;
    isRumble?: boolean;
}

const NewsItem = ({ title, description, videoUrl, linkUrl, isRumble }: NewsItem) => {
    const bgColor = useColorModeValue('gray.100', 'gray.700');
    useInviteCode();
    return (
        <Box bg={bgColor} p={5} borderRadius="md" boxShadow="md">
            <Heading as="h3" size="md" mb={3}>{title}</Heading>
            <Text mb={4}>{description}</Text>
            {videoUrl && (
                <AspectRatio ratio={16 / 9} mb={4}>
                    <iframe
                        src={videoUrl}
                        title={title}
                        allowFullScreen
                        frameBorder="0"
                        className={isRumble ? "rumble" : ""}
                        width="640"
                        height="360"
                    />
                </AspectRatio>
            )}
            {linkUrl && (
                <Link href={linkUrl} isExternal color="blue.500">
                    Learn More
                </Link>
            )}
        </Box>
    );
};

export const News = () => {
    const { user } = useAuth();
    const [affiliateId, setAffiliateId] = useState('');
    const location = useLocation();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [tempAffiliateId, setTempAffiliateId] = useState('');

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const affiliateParam = searchParams.get('affiliateId');
        if (affiliateParam) {
            setAffiliateId(affiliateParam);
            localStorage.setItem('affiliateId', affiliateParam);
        } else {
            const storedAffiliateId = localStorage.getItem('affiliateId');
            if (storedAffiliateId) {
                setAffiliateId(storedAffiliateId);
            }
        }
    }, [location]);

    const generateGenericAffiliateId = () => {
        // This is a simple example. In a real app, you'd want to generate this on the server
        const genericId = 'GEN' + Math.random().toString(36).substr(2, 9);
        setAffiliateId(genericId);
        localStorage.setItem('affiliateId', genericId);
        onClose();
    };

    const handleSetAffiliateId = () => {
        if (tempAffiliateId) {
            setAffiliateId(tempAffiliateId);
            localStorage.setItem('affiliateId', tempAffiliateId);
            onClose();
        }
    };

    const getShareableLink = () => {
        const baseUrl = window.location.origin + window.location.pathname;
        return `${baseUrl}?affiliateId=${affiliateId}`;
    };

    return (
        <Box minHeight="100vh" display="flex" flexDirection="column">
            <NavbarWithCallToAction />

            <Container maxW="container.xl" flex={1} py={10}>
                <VStack spacing={10} align="stretch">
                    <Box textAlign="center">
                        <Heading as="h1" size="2xl" mb={4}>
                            Recent Events & News
                        </Heading>
                        <Text fontSize="xl">
                            Discover why we're working to decentralize the money system and economies.
                        </Text>
                    </Box>

                    <NewsItem
                        title="Dr. David Hughes on Global Technocracy and How to Escape"
                        description="Dr. David Hughes, an expert on psychological warfare, Covid-19, the deep state, intelligence crime, technocracy, and resurgent totalitarianism, discusses the military operation underfoot and how to effectively resist."
                        videoUrl="https://rumble.com/embed/v50gx0f/?pub=4"
                        linkUrl="https://rumble.com/v52t5su-dr-david-hughes-on-global-technocracy-and-how-to-escape.html"
                        isRumble={true}
                    />

                    {/* <NewsItem
                        title="Ed Dowd on the Economy, CBDCs, and Bitcoin"
                        description={
                             "Edward Dowd, a founding partner with Phinance Technologies, discusses crucial economic topics and the future of finance. Key points discussed: The current state of the economy and potential stock market crash, Bank consolidations and predictions for regional banks, How CBDCs might work and their implications, Modern Monetary Theory (MMT), Bitcoin as a solution to the broken financial system, Political divide and its impact on society. Follow Ed on X: @DowdEdward"
                        }
                        videoUrl="https://salmon-xenogeneic-dog-987.mypinata.cloud/ipfs/QmdJgRgVBvLMGbraFfFEMmey6qr5e4YWYeLTgjkByduUn4?pinataGatewayToken=HAMsT2G6tnoStVHWOMakvZ9dqs5_5zrpeRFgJqZsjlCr5R_zOpTf5OWNmqQ2Hbm7"
                    /> */}

                    {/* <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                        <NewsItem
                            title="The Importance of Decentralization"
                            description="Learn about the critical role of decentralization in creating a more equitable and resilient economic system."
                            linkUrl="#"
                            videoUrl="https://www.youtube.com/watch?v=dadf0Qw0eyw"
                            isRumble={false}
                        />
                        <NewsItem
                            title="Blockchain Technology and Financial Freedom"
                            description="Explore how blockchain technology is paving the way for greater financial autonomy and transparency."
                            linkUrl="#"
                            videoUrl="https://www.youtube.com/embed/50Vgw-o_y44"
                        />
                    </SimpleGrid> */}

                    <Box>
                        <Heading as="h2" size="xl" mb={4}>
                            Resources
                        </Heading>
                        <VStack align="start" spacing={4}>
                            <Link href="https://www.academia.edu/118269356/Support" isExternal color="blue.500">
                                Support Dr. David Hughes' Work
                            </Link>
                            <Link href="https://link.springer.com/book/10.1007/978-3-031-41850-1" isExternal color="blue.500">
                                Free Book Download: Covid-19: Psychological Operations, and the War for Technocracy
                            </Link>
                            <Link href="https://ulincoln.academia.edu/DavidHughes/Books" isExternal color="blue.500">
                                Dr. David Hughes' Books
                            </Link>
                        </VStack>
                    </Box>

                    <Box mt={10} textAlign="center">
                        <Heading as="h2" size="lg" mb={4}>
                            Share and Earn
                        </Heading>
                        {user ? (
                            <>
                                <Text mb={4}>
                                    Share this page using your unique affiliate link:
                                </Text>
                                {affiliateId ? (
                                    <Box>
                                        <Center>
                                            <QRCodeSVG
                                                value={getShareableLink()}
                                                size={200}
                                                bgColor={"#ffffff"}
                                                fgColor={"#000000"}
                                                level={"L"}
                                                includeMargin={false}
                                            />
                                        </Center>
                                        <Text mt={2}>Your Affiliate ID: {affiliateId}</Text>
                                        <Text mt={2}>Shareable Link: {getShareableLink()}</Text>
                                    </Box>
                                ) : (
                                    <Button colorScheme="blue" onClick={onOpen}>
                                        Set Affiliate ID
                                    </Button>
                                )}
                            </>
                        ) : (
                            <Button colorScheme="blue" onClick={() => navigate('/login')}>
                                Login to Get Your Affiliate Link
                            </Button>
                        )}
                    </Box>
                </VStack>
            </Container>

            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Set Affiliate ID</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Input
                            placeholder="Enter your affiliate ID"
                            value={tempAffiliateId}
                            onChange={(e) => setTempAffiliateId(e.target.value)}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleSetAffiliateId}>
                            Set ID
                        </Button>
                        <Button variant="ghost" onClick={generateGenericAffiliateId}>
                            Generate Generic ID
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <FooterWithFourColumns />
        </Box>
    );
};

export default News;