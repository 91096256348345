// pages/Login.tsx

import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    VStack,
    Heading,
    useToast,
    Flex,
    Text,
} from "@chakra-ui/react";
import { useAuth } from "../contexts/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";

export const Login = () => {
    const [email, setEmail] = useState("");
    const { login, isLoading } = useAuth();
    const toast = useToast();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const pendingInviteCode = localStorage.getItem('pendingInviteCode');
        if (pendingInviteCode) {
            toast({
                title: "Invite Code Detected",
                description: "Log in to use the invite code.",
                status: "info",
                duration: 5000,
                isClosable: true,
            });
        }
    }, [toast]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const result = await login(email);
            if (typeof result === 'string' && result === 'incomplete_profile') {
                toast({
                    title: "Profile Incomplete",
                    description: "Please complete your profile.",
                    status: "info",
                    duration: 5000,
                    isClosable: true,
                });
                navigate('/profiledetails');
            } else {
                toast({
                    title: "Login successful",
                    status: "success",
                    duration: 3000,
                    isClosable: true,
                });
                const from = (location.state as any)?.from || '/';
                navigate(from);
            }
        } catch (error) {
            console.error('Login error:', error);
            toast({
                title: "Login failed",
                description: error instanceof Error ? error.message : "Please check your email and try again.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Flex direction="column" minHeight="100vh">
            <NavbarWithCallToAction />
            <Box flex="1" display="flex" justifyContent="center" alignItems="center">
                <VStack spacing={6} width="md">
                    <Heading as="h1" size="xl">
                        Login
                    </Heading>
                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <FormControl id="email" isRequired>
                            <FormLabel>Email address</FormLabel>
                            <Input
                                type="email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormControl>
                        <Button
                            type="submit"
                            colorScheme="blue"
                            mt={4}
                            width="full"
                            isLoading={isLoading}
                            loadingText="Logging in"
                        >
                            Login
                        </Button>
                    </form>
                    <Text fontSize="sm">
                        Don't have an account? Login with your email to create one.
                    </Text>
                </VStack>
            </Box>
            <FooterWithFourColumns />
        </Flex>
    );
};

export default Login;