import React, { useEffect, useState } from 'react';
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Heading,
} from '@chakra-ui/react';
// import { getAllUsers } from '../services/userService';

import { getApiUrl } from '../utils/helpers';

import { User } from '../types';  // Import your User type
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

export const Metrics: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const apiUrl = getApiUrl();

    useEffect(() => {
        const fetchUsers = async () => {
            // try {
            //     const fetchedUsers = await getAllUsers(apiUrl);
            //     setUsers(fetchedUsers);
            // } catch (error) {
            //     console.error('Error fetching users:', error);
            // }
        };

        fetchUsers();
    }, []);

    return (
        <Box>
            <NavbarWithCallToAction />
            <Box p={5}>
                <Heading as="h1" size="xl" mb={5}>
                    Metrics
                </Heading>
                <TableContainer>
                    <Table variant="simple">
                        <TableCaption>List of all users</TableCaption>
                        <Thead>
                            <Tr>

                                <Th>Name</Th>
                                <Th>Email</Th>
                                <Th>Phone Number</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {users.map((user) => (
                                <Tr key={user.id}>

                                    <Td>{user.firstName}</Td>
                                    <Td>{user.email}</Td>
                                    <Td>{user.phoneNumber}</Td>
                                    {/* Add more table cells as needed */}
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>

            <FooterWithFourColumns />


        </Box>

    );
};