//src/pages/GroupBeefBuy.tsx
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Heading,
    Text,
    VStack,
    List,
    ListItem,
    ListIcon,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Checkbox,
    Center,
    Flex,
    Image,
    Progress,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useAuth } from '../contexts/AuthContext';
import { useInviteCode } from '../hooks/useInviteCode';
import { useMutation, gql } from '@apollo/client';

const CREATE_BEEF_PURCHASE_PLEDGE = gql`
    mutation CreateBeefPurchasePledge($userEmail: String!, $pledgeAmount: Float!) {
        createBeefPurchasePledge(userEmail: $userEmail, pledgeAmount: $pledgeAmount) {
            id
            status
        }
    }
`;

const GroupBeefBuy = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pledgeId, setPledgeId] = useState('');
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    useInviteCode();
    const { user, isLoading } = useAuth();

    const [createBeefPurchasePledge] = useMutation(CREATE_BEEF_PURCHASE_PLEDGE);

    const handlePledgeSubmit = async () => {
        if (!agreeToTerms) {
            toast({
                title: "Agreement Required",
                description: "Please agree to the terms before submitting your pledge.",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (user) {
            try {
                const { data } = await createBeefPurchasePledge({
                    variables: {
                        userEmail: user.email,
                        pledgeAmount: 125,
                    },
                });
                setPledgeId(data.createBeefPurchasePledge.id);
                setIsModalOpen(true);
            } catch (error) {
                console.error('Error creating beef purchase pledge:', error);
                toast({
                    title: "Error",
                    description: "Failed to submit pledge. Please try again.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            navigate('/login', { state: { from: location } });
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        toast({
            title: "Pledge Submitted",
            description: "Thank you for your interest. We'll be in contact shortly.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
    };

    if (isLoading) {
        return <Box>Loading...</Box>;
    }

    return (
        <Box>
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={8}>
                <VStack spacing={8} align="stretch">
                    <Flex direction={{ base: 'column', md: 'row' }} align="center" justify="space-between">
                        <Box flex={1}>
                            <Heading as="h1" size="2xl">Group Buy: Half a Beef</Heading>
                            <Heading as="h2" size="xl" mt={2}>Join Our Bulk Meat Purchase</Heading>
                        </Box>
                        <Box flex={1} ml={{ base: 0, md: 8 }} mt={{ base: 4, md: 0 }}>
                            <Image
                                src="/product_images/12BeefBox.png"
                                alt="Half a Beef"
                                maxWidth="100%"
                                borderRadius="md"
                                boxShadow="lg"
                            />
                        </Box>
                    </Flex>

                    <Text fontSize="xl">
                        Join our group purchase of half a beef! Split between 16 people, this is an excellent opportunity to stock up on high-quality, locally sourced beef at a great price.
                    </Text>

                    <Box>
                        <Heading as="h2" size="xl" mb={4}>About the Offer</Heading>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Half a beef (approximately 90 kg of meat)
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Locally sourced, grass-fed and finished beef
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Total cost: $2,000 split between 16 people
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Your share: $125 for approximately 5.625 kg of beef
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Delivery to a central location for pickup
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Save 30% off the retail value
                            </ListItem>
                        </List>
                    </Box>

                    <Box>
                        <Heading as="h2" size="xl" mb={4}>What's In The Box</Heading>
                        <Text mb={4}>
                            Each box is around 90kg, the equivalent of 1/2 of the carcase. Remember, every animal we process is slightly different, so weights may vary slightly every time, but we do our best to manage that and keep it as close as possible.
                        </Text>
                        <Text mb={4}>In the box, you will receive a mix of cuts that consists of:</Text>
                        <List spacing={2}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Scotch Fillet Steak
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Rump Steak
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Eye Fillet Steak
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Sirloin Steak
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Stir Fry Strips
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                BBQ Steak
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Y-Bone Steak
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Mince
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Sausages
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Blade Roast
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Osso Bucco
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Diced Beef
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Nitrate-Free Corned Beef
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Brisket Roast
                            </ListItem>
                        </List>
                    </Box>

                    <Box>
                        <Heading as="h2" size="xl" mb={4}>Why Forage Farms Beef</Heading>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                100% grass-fed and finished
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Rotated through diverse paddocks of pasture in a time-controlled manner
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Chemical-free pastures
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                No pesticides, hormones, or antibiotics
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                No grains, only diverse mix of grasses, legumes, forbs, herbs, and more
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                No Nasties, No Nonsense beef
                            </ListItem>
                        </List>
                    </Box>

                    <Box>
                        <Heading as="h2" size="xl" mb={4}>Progress</Heading>
                        <Text fontSize="lg" mb={2}>2 out of 16 pledges received</Text>
                        <Progress value={12.5} size="lg" colorScheme="green" />
                    </Box>

                    <Box>
                        <Heading as="h2" size="xl" mb={4}>How It Works</Heading>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="blue.500" />
                                Pledge your $125 share
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="blue.500" />
                                Once we have 16 pledges, we'll confirm the purchase
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="blue.500" />
                                The beef will be delivered to a central location
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="blue.500" />
                                You'll be notified to come pick up your share (approx. 5.625 kg)
                            </ListItem>
                        </List>
                    </Box>

                    <Box>
                        {user ? (
                            <>
                                <Text fontSize="lg" mb={4}>
                                    By agreeing to participate, you're pledging to contribute $125 towards the purchase of half a beef, to be split between 16 people.
                                </Text>
                                <Checkbox
                                    isChecked={agreeToTerms}
                                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                                    mb={4}
                                >
                                    I agree to pledge $125 as part of this group purchase
                                </Checkbox>
                                <Center>
                                    <Button colorScheme="blue" size="lg" mt={4} onClick={handlePledgeSubmit} isDisabled={!agreeToTerms}>
                                        Submit Pledge
                                    </Button>
                                </Center>
                            </>
                        ) : (
                            <Center>
                                <Button colorScheme="blue" size="lg" onClick={() => navigate('/login', { state: { from: location } })}>
                                    Login to Pledge
                                </Button>
                            </Center>
                        )}
                    </Box>
                </VStack>
            </Container>

            <Modal isOpen={isModalOpen} onClose={handleModalClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Beef Purchase Pledge Submitted</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Thank you for your interest in our group beef purchase!</Text>
                        <Text mt={2}>Pledge ID: {pledgeId}</Text>
                        <Text mt={4}>We've recorded your pledge of $125 for approximately 5.625 kg of beef.</Text>
                        <Text mt={2}>We'll be in touch soon with more information about next steps and when to expect delivery.</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleModalClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <FooterWithFourColumns />
        </Box>
    );
};

export default GroupBeefBuy;