// src/pages/SpecialtyBuilds.tsx
import React from "react";
import {
    Box,
    Container,
    Heading,
    Text,
    Flex,
    VStack,
    Button,
    Grid,
    GridItem,
    Image,
    Icon,
    SimpleGrid,
    useColorModeValue,
    keyframes,
    useDisclosure,
    Center,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { FaHammer, FaHome, FaClipboardCheck, FaPhone } from "react-icons/fa";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { QuoteModal } from '../components/QuoteModal';


import { IconType } from 'react-icons';
import { Logo } from "../components/Logo";

interface FeatureCardProps {
    icon: IconType;
    title: string;
    description: string;
}


const MotionBox = motion(Box);

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const FeatureCard = ({ icon, title, description }: FeatureCardProps) => (

    <MotionBox
        bg={useColorModeValue('white', 'gray.800')}
        p={6}
        borderRadius="lg"
        boxShadow="0 4px 6px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.06)"
        transition="all 0.3s"
        _hover={{
            transform: "translateY(-5px)",
            boxShadow: "0 10px 20px rgba(0, 0, 0, 0.15), 0 6px 6px rgba(0, 0, 0, 0.1)",
        }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        whileHover={{ scale: 1.05 }}
    >
        <VStack align="start" spacing={4}>
            <Icon as={icon} w={10} h={10} color="blue.500" />
            <Heading as="h3" size="md">
                {title}
            </Heading>
            <Text>{description}</Text>
        </VStack>
    </MotionBox>
);

export const SpecialtyBuilds = () => {
    const quoteModal = useDisclosure();
    const bgGradient = "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)";
    const textColor = "gray.800";
    const sectionBg = "white";

    return (
        <Flex direction="column" minHeight="100vh">
            <NavbarWithCallToAction />

            <Box flex="1" bg="gray.50">
                <Container maxW="container.xl" py={16}>
                    <VStack spacing={20} align="stretch">
                        {/* Hero Section */}
                        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={8} alignItems="center">
                            <GridItem>
                                <MotionBox
                                    initial={{ opacity: 0, x: -50 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <VStack align="start" spacing={6}>
                                        <Heading as="h1" size="2xl" color={textColor}>
                                            Crafting Exceptional Homes in Southeast Queensland
                                        </Heading>
                                        <Text fontSize="xl" color={textColor}>
                                            Lifetimes of expertise in specialty builds, boutique renovations, and Queenslander restorations.
                                        </Text>

                                        <Button

                                            size="lg"
                                            boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
                                            _hover={{
                                                boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
                                                transform: "translateY(-2px)",
                                            }}
                                            onClick={quoteModal.onOpen}
                                        >
                                            Schedule Your Free Consultation
                                        </Button>
                                    </VStack>
                                </MotionBox>
                            </GridItem>
                            <GridItem>
                                <MotionBox
                                    initial={{ opacity: 0, scale: 0.9 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <Image
                                        src="/builds/north_straddie/north_straddie_roof.jpeg"
                                        alt="Luxury custom home"
                                        borderRadius="lg"
                                        boxShadow="2xl"
                                    />
                                </MotionBox>
                            </GridItem>
                        </Grid>

                        {/* Our Expertise Section */}
                        <VStack spacing={8} align="stretch">
                            <Heading as="h2" size="xl" textAlign="center" color={textColor}>
                                Our Specialty Build Expertise
                            </Heading>
                            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8}>
                                <FeatureCard
                                    icon={FaHome}
                                    title="Queenslander Renovations"
                                    description="Breathe new life into classic Queenslanders while preserving their timeless charm."
                                />
                                <FeatureCard
                                    icon={FaHammer}
                                    title="Custom Deck Creations"
                                    description="Design and build stunning outdoor spaces that perfectly complement your lifestyle."
                                />
                                <FeatureCard
                                    icon={FaClipboardCheck}
                                    title="Bespoke Home Transformations"
                                    description="Turn your dream home into reality with our tailored renovation services."
                                />
                            </SimpleGrid>
                        </VStack>

                        {/* Featured Projects */}
                        <VStack spacing={8} align="stretch">
                            <Heading as="h2" size="xl" textAlign="center" color={textColor}>
                                Recent Projects
                            </Heading>
                            <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={8}>
                                <GridItem>
                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <Image
                                            src="/builds/north_straddie/deck.jpeg"
                                            alt="Queenslander renovation"
                                            borderRadius="lg"
                                            boxShadow="2xl"
                                        />
                                        <Text mt={2} fontWeight="bold" color={textColor}>
                                            Scheduled Maintenance to a House Built on Straddie Years Ago
                                        </Text>
                                    </MotionBox>
                                </GridItem>
                                <GridItem>
                                    <MotionBox
                                        whileHover={{ scale: 1.05 }}
                                        transition={{ duration: 0.3 }}
                                    >
                                        <Image
                                            src="builds/kenmore/454528733_1027088008471276_4517921585748402444_n.jpeg"
                                            alt="Custom deck"
                                            borderRadius="lg"
                                            boxShadow="2xl"
                                        />
                                        <Text mt={2} fontWeight="bold" color={textColor}>
                                            New Carport in Kenmore
                                        </Text>
                                    </MotionBox>
                                </GridItem>
                            </Grid>
                        </VStack>

                        {/* Why Choose Us */}
                        <MotionBox
                            bg={sectionBg}
                            p={8}
                            borderRadius="lg"
                            boxShadow="xl"
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5 }}
                        >
                            <VStack spacing={8} align="stretch">
                                <Heading as="h2" size="xl" textAlign="center" color={textColor}>
                                    Why Choose Our Specialty Build Services?
                                </Heading>
                                <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={8}>
                                    <GridItem>
                                        <VStack align="start" spacing={4}>
                                            <Heading as="h3" size="md" color={textColor}>Lifetimes of Expertise</Heading>
                                            <Text color={textColor}>Benefit from our decades of experience in advanced carpentry and construction techniques.</Text>
                                            <Heading as="h3" size="md" color={textColor}>Local Knowledge</Heading>
                                            <Text color={textColor}>Deep understanding of Southeast Queensland's architectural styles and building requirements.</Text>
                                        </VStack>
                                    </GridItem>
                                    <GridItem>
                                        <VStack align="start" spacing={4}>
                                            <Heading as="h3" size="md" color={textColor}>Attention to Detail</Heading>
                                            <Text color={textColor}>Meticulous craftsmanship ensures every aspect of your project is perfect.</Text>
                                            <Heading as="h3" size="md" color={textColor}>Tailored Solutions</Heading>
                                            <Text color={textColor}>Custom designs that blend traditional charm with modern living needs.</Text>
                                        </VStack>
                                    </GridItem>
                                </Grid>
                            </VStack>
                        </MotionBox>

                        {/* Call to Action */}
                        <MotionBox
                            // bg="blue.500"
                            // bg={bgGradient}
                            p={12}
                            borderRadius="lg"
                            boxShadow="2xl"
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 0.5 }}
                            bg="#d0c9bd"
                        // boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
                        >
                            <VStack spacing={8} align="center">
                                <Heading as="h2" size="xl" textAlign="center" color="black">
                                    Ready to Start Your Dream Project?
                                </Heading>
                                <Text fontSize="lg" textAlign="center" maxW="800px" color="black">
                                    From Brisbane to the Gold Coast and Sunshine Coast, we're here to bring your vision to life. Let's create something extraordinary together.
                                </Text>
                                <Button

                                    size="lg"
                                    boxShadow="0 4px 6px rgba(0, 0, 0, 0.1)"
                                    _hover={{
                                        boxShadow: "0 6px 8px rgba(0, 0, 0, 0.15)",
                                        transform: "translateY(-2px)",
                                    }}
                                    onClick={quoteModal.onOpen}
                                >
                                    Schedule Your Free Consultation
                                </Button>
                            </VStack>
                        </MotionBox>
                    </VStack>
                </Container>
            </Box>





            <FooterWithFourColumns />









            <Modal isOpen={quoteModal.isOpen} onClose={quoteModal.onClose}>
                <ModalOverlay />
                <ModalContent bg="#d0c9bd">
                    <ModalHeader p={0}>
                        <Center pt={6}>
                            <Logo />
                        </Center>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4}>
                            <Heading size="lg" textAlign="center">Get a Free Quote</Heading>
                            <Text textAlign="center">
                                For a quick chat and a free quote, please call us now on:
                            </Text>
                            <Button
                                leftIcon={<FaPhone />}
                                colorScheme="gray"
                                size="lg"
                                width="full"
                                onClick={() => window.location.href = 'tel:0488041641'}

                            >
                                0488 041 641
                            </Button>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="gray" mr={3} onClick={quoteModal.onClose} variant="secondary">
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>



        </Flex>
    );
};

export default SpecialtyBuilds;