// src/pages/DeckMaintenance.tsx
import React from "react";
import {
    Box,
    Container,
    Heading,
    Text,
    Flex,
    VStack,
} from "@chakra-ui/react";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";

export const DeckMaintenance = () => {
    return (
        <Flex direction="column" minHeight="100vh">
            <NavbarWithCallToAction />

            <Box flex="1" display="flex" alignItems="center" justifyContent="center">
                <Container maxW="container.lg">
                    <VStack spacing={8} align="center">
                        <Heading as="h1" size="2xl" textAlign="center">
                            Deck Maintenance Services
                        </Heading>
                        <Text fontSize="xl" textAlign="center">
                            Coming Soon
                        </Text>
                    </VStack>
                </Container>
            </Box>

            <FooterWithFourColumns />
        </Flex>
    );
};

export default DeckMaintenance;