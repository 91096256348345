import React, { useState } from 'react';
import { Badge, Box, Button, Container, Heading, Img, Stack, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useColorModeValue, Center, VStack, useDisclosure } from '@chakra-ui/react';
import { FaHammer, FaPhone, FaEnvelope } from 'react-icons/fa';
import Logo from './Logo';
import { scroller } from 'react-scroll';

export const AboutSection = () => {
    const [isOpen, setIsOpen] = useState(false);
    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const textColor = useColorModeValue('gray.800', 'gray.100');
    const quoteModal = useDisclosure();

    const onOpen = () => setIsOpen(true);
    const onClose = () => setIsOpen(false);



    const scrollToServices = () => {
        scroller.scrollTo('queenslander-renovations', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart'
        });
    };

    return (
        <Box position="relative" height={{ base: 'auto', lg: '720px' }} bg={bgColor}>
            <Container maxW="container.xl" py={{ base: '16', md: '24' }} height="full">
                <Stack direction={{ base: 'column', lg: 'row' }} spacing={{ base: '16' }} height="full">
                    <Stack spacing={{ base: '8', md: '12' }} maxW={{ base: 'full', lg: '50%' }} justifyContent="center">
                        <Stack spacing="4">
                            <Badge variant="pill" colorScheme="blue" alignSelf="start" size={{ base: 'md', md: 'lg' }}>
                                Expert Construction Services
                            </Badge>
                            <Stack spacing={{ base: '4', md: '6' }}>
                                <Heading size={{ base: 'xl', md: '2xl' }} color={textColor}>SJT Constructions</Heading>
                                <Text fontSize={{ base: 'lg', md: 'xl' }} color="fg.muted">
                                    Building your dreams, one project at a time. Expert carpentry and construction services for residential and commercial projects.
                                </Text>
                            </Stack>
                        </Stack>
                        <Stack direction={{ base: 'column', sm: 'row' }} spacing="3">

                            <Button leftIcon={<FaHammer />} onClick={quoteModal.onOpen}>
                                Get a Quote
                            </Button>
                            <Button variant="outline" onClick={scrollToServices}>
                                Our Services
                            </Button>
                        </Stack>
                    </Stack>
                    <Box flexGrow={1} position={{ base: 'relative', lg: 'absolute' }} right="0" top="0" w={{ base: 'full', lg: '50%' }} height={{ base: '400px', lg: 'full' }}
                        sx={{ clipPath: { lg: 'polygon(7% 0%, 100% 0%, 100% 100%, 0% 100%)' } }}>
                        <Img boxSize="full" objectFit="cover" src="/product_images/queenslander.jpg" alt="Queenslander house" />
                    </Box>
                </Stack>
            </Container>
            <Modal isOpen={quoteModal.isOpen} onClose={quoteModal.onClose}>
                <ModalOverlay />
                <ModalContent bg="#d0c9bd">
                    <ModalHeader p={0}>
                        <Center pt={6}>
                            <Logo />
                        </Center>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <VStack spacing={4}>
                            <Heading size="lg" textAlign="center">Get a Free Quote</Heading>
                            <Text textAlign="center">
                                For a quick chat and a free quote, please call us now on:
                            </Text>
                            <Button
                                leftIcon={<FaPhone />}
                                colorScheme="gray"
                                size="lg"
                                width="full"
                                onClick={() => window.location.href = 'tel:0488041641'}

                            >
                                0488 041 641
                            </Button>
                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="gray" mr={3} onClick={quoteModal.onClose} variant="secondary">
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Box>
    );
};