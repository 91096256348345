import React, { useState } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    VStack,
    Heading,
    useToast,
    Flex,
    Textarea,
    Select,
} from '@chakra-ui/react';
import { useMutation, gql } from '@apollo/client';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useAuth } from '../contexts/AuthContext';

const ADD_PRODUCT = gql`
  mutation AddProduct($input: ProductInput!) {
    createProduct(input: $input) {
      id
      name
      description
      price
      category
      image
      brand {
        id
        name
      }
    }
  }
`;

const AddProduct = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [price, setPrice] = useState('');
    const [category, setCategory] = useState('');
    const [image, setImage] = useState('');
    const [brandId, setBrandId] = useState('');
    const { user } = useAuth();
    const toast = useToast();

    const [addProduct, { loading, error }] = useMutation(ADD_PRODUCT);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const productInput = {
                name,
                description,
                price: parseFloat(price),
                category,
                image,
            };

            // Only add brandId if it's provided
            // if (brandId) {
            //     productInput.brandId = brandId;
            // }

            const { data } = await addProduct({
                variables: {
                    input: productInput,
                },
            });
            console.log('Product added:', data.createProduct);
            // Handle success (e.g., show a success message, clear form, etc.)
        } catch (err) {
            console.error('Error adding product:', err);
            // Handle error (e.g., show error message to user)
        }
    };

    return (
        <Flex direction="column" minHeight="100vh">
            <NavbarWithCallToAction />
            <Box flex={1} padding={8}>
                <VStack spacing={8} width="full" maxWidth="500px" margin="auto">
                    <Heading>Add New Product</Heading>

                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <FormControl id="name" marginBottom={4} isRequired>
                            <FormLabel>Product Name</FormLabel>
                            <Input
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </FormControl>
                        <FormControl id="description" marginBottom={4}>
                            <FormLabel>Description</FormLabel>
                            <Textarea
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            />
                        </FormControl>
                        <FormControl id="price" marginBottom={4} isRequired>
                            <FormLabel>Price</FormLabel>
                            <Input
                                type="number"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </FormControl>
                        <FormControl id="category" marginBottom={4}>
                            <FormLabel>Category</FormLabel>
                            <Input
                                value={category}
                                onChange={(e) => setCategory(e.target.value)}
                            />
                        </FormControl>
                        <FormControl id="image" marginBottom={4}>
                            <FormLabel>Image URL</FormLabel>
                            <Input
                                value={image}
                                onChange={(e) => setImage(e.target.value)}
                            />
                        </FormControl>
                        {/* <FormControl id="brandId" marginBottom={4} isRequired>
                            <FormLabel>Brand ID</FormLabel>
                            <Input
                                value={brandId}
                                onChange={(e) => setBrandId(e.target.value)}
                            />
                        </FormControl> */}

                        <Button type="submit" colorScheme="blue" width="full" isLoading={loading}>
                            Add Product
                        </Button>
                    </form>
                </VStack>
            </Box>
            <FooterWithFourColumns />
        </Flex>
    );
};

export default AddProduct;