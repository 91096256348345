import React, { useState } from "react";
import {
    Box,
    Container,
    Heading,
    Text,
    Button,
    Image,
    Center,
    Flex,
    VStack,
    Link,
    Spacer,
    List,
    HStack,
    Icon,
    ListIcon,
    ListItem,
    SimpleGrid,
    useColorModeValue,
    Grid,
    GridItem,
    Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { NavbarWithCallToAction } from "../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction";
import { FooterWithFourColumns } from "../components/chakra/FooterWithFourColumns/FooterWithFourColumns";
import { AboutSection } from "../components/AboutSection";
import { FaHome, FaTools, FaClipboardCheck, FaShieldAlt, FaPhoneAlt, FaArrowRight } from "react-icons/fa";

const MotionBox = motion(Box);

export const Home = () => {
    const bgColor = useColorModeValue("gray.50", "gray.900");
    const cardBg = useColorModeValue("white", "gray.800");
    const textColor = useColorModeValue("gray.800", "white");


    const [expandedIndex, setExpandedIndex] = useState(0);

    return (
        <Flex direction="column" minHeight="100vh" bg={bgColor}>
            <NavbarWithCallToAction />

            <AboutSection />

            <Spacer />

            {/* Specialty Builds Section */}
            <MotionBox
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                py={20}
                bg={cardBg}
                boxShadow="xl"
                borderRadius="lg"
                mx={4}
                my={8}
            >
                <Container maxW="container.xl">
                    <VStack spacing={12} align="stretch">
                        <Heading as="h2" size="2xl" textAlign="center" color={textColor}>
                            Specialty Builds: Crafting Your Dream Home
                        </Heading>
                        <Text fontSize="xl" textAlign="center" color={textColor}>
                            From from Bespoke Builds to Queenslander Renovations to Backyard Renovations, we bring lifetimes expertise to every project.
                        </Text>
                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                            {[
                                { icon: FaHome, title: "Queenslander Renovations", description: "Breathe new life into classic Queenslanders while preserving their timeless charm and character." },
                                { icon: FaTools, title: "Custom Builds", description: "Turn your vision into reality with our bespoke home design and construction services." },
                                { icon: FaClipboardCheck, title: "Expert Craftsmanship", description: "Benefit from our meticulous attention to detail and commitment to quality in every aspect of your project." },
                            ].map((item, index) => (
                                <MotionBox
                                    key={index}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    bg={cardBg}
                                    p={8}
                                    borderRadius="lg"
                                    boxShadow="lg"
                                >
                                    <VStack align="start" spacing={4}>
                                        <Icon as={item.icon} color="green.500" boxSize={10} />
                                        <Heading as="h3" size="lg" color={textColor}>{item.title}</Heading>
                                        <Text color={textColor}>{item.description}</Text>
                                    </VStack>
                                </MotionBox>
                            ))}
                        </SimpleGrid>
                        <Text fontSize="xl" textAlign="center" fontWeight="bold" color={textColor}>
                            Experience the difference of working with Southeast Queensland's premier specialty builders.
                        </Text>
                        <Center>
                            <Button
                                as={Link}
                                href="/specialtybuilds"
                                size="lg"
                                colorScheme="green"
                                rightIcon={<Icon as={FaArrowRight} />}
                                _hover={{ transform: "translateY(-2px)", boxShadow: "lg" }}
                                transition="all 0.2s"
                            >
                                Explore Our Specialty Builds
                            </Button>
                        </Center>
                    </VStack>
                </Container>
            </MotionBox>

            <Spacer />

            {/* Deck Maintenance Section */}
            <MotionBox
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                py={20}
                bg={cardBg}
                boxShadow="xl"
                borderRadius="lg"
                mx={4}
                my={8}
            >
                <Container maxW="container.xl">
                    <VStack spacing={12} align="stretch">
                        <Heading as="h2" size="2xl" textAlign="center" color={textColor}>
                            Specialist Deck Maintenance Services
                        </Heading>
                        <Text fontSize="xl" textAlign="center" color={textColor}>
                            Protect your investment with our expert deck maintenance services. Regular maintenance is key to extending the life of your deck.
                        </Text>
                        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
                            {[
                                { icon: FaShieldAlt, title: "Tailored Plans", description: "Customized maintenance plans to protect your deck from Queensland's harsh climate" },
                                { icon: FaTools, title: "Professional Care", description: "Expert cleaning, sanding, and sealing services to keep your deck looking its best" },
                                { icon: FaClipboardCheck, title: "Regular Inspections", description: "Catch and address issues before they become major problems" },
                            ].map((item, index) => (
                                <MotionBox
                                    key={index}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                    bg={cardBg}
                                    p={8}
                                    borderRadius="lg"
                                    boxShadow="lg"
                                >
                                    <VStack align="start" spacing={4}>
                                        <Icon as={item.icon} color="orange.500" boxSize={10} />
                                        <Heading as="h3" size="lg" color={textColor}>{item.title}</Heading>
                                        <Text color={textColor}>{item.description}</Text>
                                    </VStack>
                                </MotionBox>
                            ))}
                        </SimpleGrid>





                        {/* Featured Projects */}

                        {/* Recent Projects */}
                        <VStack spacing={12} align="stretch">
                            <Heading as="h2" size="xl" textAlign="center" color={textColor}>
                                Recent Projects
                            </Heading>


                            <Accordion index={expandedIndex} onChange={(index) => setExpandedIndex(index as number)} allowMultiple>
                                {/* Island Time Projects */}
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                <Heading as="h3" size="lg" color={textColor}>
                                                    Island Time Projects
                                                </Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        {/* North Straddie Renovation */}
                                        <Box mb={20}>
                                            <Heading as="h4" size="md" mb={4} color={textColor}>
                                                North Straddie Renovation
                                            </Heading>
                                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                                                {[1, 2, 3, 4, 5, 6].map((i) => (
                                                    <MotionBox key={i} whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
                                                        <Image
                                                            src={`/builds/north_straddie_renovation/${i}.jpg`}
                                                            alt={`North Straddie renovation image ${i}`}
                                                            borderRadius="lg"
                                                            boxShadow="2xl"
                                                        />
                                                    </MotionBox>
                                                ))}
                                            </SimpleGrid>
                                        </Box>

                                        {/* Lighthouse */}
                                        <Box mb={20}>
                                            <Heading as="h4" size="md" mb={4} color={textColor}>
                                                Lighthouse
                                            </Heading>
                                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                                                {[1, 2, 3, 4, 5, 6].map((i) => (
                                                    <MotionBox key={i} whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
                                                        <Image
                                                            src={`/builds/light_house/${i}.jpg`}
                                                            alt={`Lighthouse image ${i}`}
                                                            borderRadius="lg"
                                                            boxShadow="2xl"
                                                        />
                                                    </MotionBox>
                                                ))}
                                            </SimpleGrid>
                                        </Box>
                                    </AccordionPanel>
                                </AccordionItem>

                                {/* Other project categories can be added here as additional AccordionItems */}
                                {/* For example: */}
                                <AccordionItem>
                                    <h2>
                                        <AccordionButton>
                                            <Box flex="1" textAlign="left">
                                                <Heading as="h3" size="lg" color={textColor}>
                                                    Urban Projects
                                                </Heading>
                                            </Box>
                                            <AccordionIcon />
                                        </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4}>
                                        {/* Add urban projects here */}
                                        {/* Taringa Retaining Wall */}
                                        <Box mb={20}>
                                            <Heading as="h4" size="lg" mb={4} color={textColor}>
                                                Taringa Retaining Wall
                                            </Heading>
                                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                                                {[1, 2, 3, 4].map((i) => (
                                                    <MotionBox key={i} whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
                                                        <Image
                                                            src={`/builds/taringa_retaining_wall/${i}.jpg`}
                                                            alt={`Taringa retaining wall image ${i}`}
                                                            borderRadius="lg"
                                                            boxShadow="2xl"
                                                        />
                                                    </MotionBox>
                                                ))}
                                            </SimpleGrid>
                                        </Box>
                                        <Spacer></Spacer>




                                        {/* Flyover Roofed Patio Bardon */}
                                        <Box mb={20}>
                                            <Heading as="h4" size="lg" mb={4} color={textColor}>
                                                Flyover Roofed Patio in Bardon
                                            </Heading>
                                            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                                                {[1, 2, 3, 4].map((i) => (
                                                    <MotionBox key={i} whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
                                                        <Image
                                                            src={`/builds/bardon_patio/${i}.jpg`}
                                                            alt={`Flyover roofed patio in Bardon image ${i}`}
                                                            borderRadius="lg"
                                                            boxShadow="2xl"
                                                        />
                                                    </MotionBox>
                                                ))}
                                            </SimpleGrid>
                                        </Box>




                                        {/* Outdoor Pavilion in Paddington */}
                                        <Box mb={20}>
                                            <Heading as="h3" size="lg" mb={4} color={textColor}>
                                                Outdoor Pavilion in Paddington
                                            </Heading>
                                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                                                {[1,].map((i) => (
                                                    <MotionBox key={i} whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
                                                        <Image
                                                            src={`/builds/paddington_pavilion/${i}.jpg`}
                                                            alt={`Outdoor pavilion in Paddington image ${i}`}
                                                            borderRadius="lg"
                                                            boxShadow="2xl"
                                                        />
                                                    </MotionBox>
                                                ))}
                                            </SimpleGrid>
                                        </Box>













                                        {/* Retaining Wall in Paddington */}
                                        <Box mb={20}>
                                            <Heading as="h3" size="lg" mb={4} color={textColor}>
                                                Retaining Wall in Paddington
                                            </Heading>
                                            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                                                {[1, 2].map((i) => (
                                                    <MotionBox key={i} whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
                                                        <Image
                                                            src={`/builds/paddington_retaining_wall/${i}.jpg`}
                                                            alt={`Retaining wall in Paddington image ${i}`}
                                                            borderRadius="lg"
                                                            boxShadow="2xl"
                                                        />
                                                    </MotionBox>
                                                ))}
                                            </SimpleGrid>
                                        </Box>








                                        {/* New Carport in Kenmore */}
                                        <Box mb={20}>
                                            <Heading as="h3" size="lg" mb={4} color={textColor}>
                                                New Carport with Big Battened Boundary Gate Access in Kenmore
                                            </Heading>
                                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                                                {[1, 2,].map((i) => (
                                                    <MotionBox key={i} whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
                                                        <Image
                                                            src={`/builds/kenmore_carport/${i}.jpg`}
                                                            alt={`New carport in Kenmore image ${i}`}
                                                            borderRadius="lg"
                                                            boxShadow="2xl"
                                                        />
                                                    </MotionBox>
                                                ))}
                                            </SimpleGrid>
                                        </Box>






                                        {/* Woolloongabba Project */}
                                        <Box mb={20}>
                                            <Heading as="h3" size="lg" mb={4} color={textColor}>
                                                Woolloongabba Multi-Feature Project
                                            </Heading>
                                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={4}>
                                                {[1, 2, 3, 4, 5, 6].map((i) => (
                                                    <MotionBox key={i} whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
                                                        <Image
                                                            src={`/builds/woolloongabba_project/${i}.jpg`}
                                                            alt={`Woolloongabba project image ${i}`}
                                                            borderRadius="lg"
                                                            boxShadow="2xl"
                                                        />
                                                        <Text mt={2} fontWeight="bold" color={textColor}>
                                                            {i === 1 ? "Deck Extension" :
                                                                i === 2 ? "Complete Stair Overhaul" :
                                                                    i === 3 ? "Custom Louvre" :
                                                                        i === 4 ? "Floating Office Desk" :
                                                                            i === 5 ? "Bathroom" :
                                                                                "Shower"}
                                                        </Text>
                                                    </MotionBox>
                                                ))}
                                            </SimpleGrid>
                                        </Box>




                                    </AccordionPanel>
                                </AccordionItem>

                                {/* Add more AccordionItems for other categories as needed */}

                            </Accordion>

















                            {/* Commercial gutter replacement at Triple P Parenting office building - indropillier*/}





                        </VStack>
                        <Text fontSize="xl" fontWeight="bold" textAlign="center" color={textColor}>
                            Get Started:
                        </Text>
                        <HStack spacing={8} justify="center">
                            {[
                                { icon: FaPhoneAlt, text: "Call for a Quote" },
                                { icon: FaHome, text: "We Visit Your Deck" },
                                { icon: FaClipboardCheck, text: "Customized Maintenance Plan" },
                            ].map((step, index) => (
                                <VStack key={index} spacing={4}>
                                    <Icon as={step.icon} w={12} h={12} color="green.500" />
                                    <Text fontWeight="bold" color={textColor}>{`${index + 1}. ${step.text}`}</Text>
                                </VStack>
                            ))}
                        </HStack>






                        <Text textAlign="center" fontStyle="italic" fontSize="lg" color={textColor}>
                            Call us today to discuss your deck's needs and receive expert advice on the best maintenance schedule for your specific situation.
                        </Text>
                    </VStack>
                </Container>
            </MotionBox>

            <Spacer />

            <FooterWithFourColumns />
        </Flex>
    );
};

export default Home;