// src/pages/Wallet.tsx

import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    HStack,
    Input,
    Button,
    useToast,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Spinner,
    Tooltip,
    useClipboard,
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useAuth } from '../contexts/AuthContext';



const GET_USER_BALANCE = gql`
  query GetUserBalance($userId: ID!) {
    getUserBalance(userId: $userId) {
      balance
      lastUpdated
    }
  }
`;

const GET_USER_TRANSACTIONS = gql`
  query GetUserTransactions($userId: ID!, $limit: Int, $offset: Int) {
    getUserTransactions(userId: $userId, limit: $limit, offset: $offset) {
      id
      type
      amount
      status
      timestamp
      paymentRequest
    }
  }
`;


const CREATE_INVOICE = gql`
  mutation CreateInvoice($userId: ID!, $amount: Float!, $memo: String) {
    createInvoice(userId: $userId, amount: $amount, memo: $memo) {
      id
      status
      lndInvoiceId
    }
  }
`;

const PAY_INVOICE = gql`
  mutation PayInvoice($userId: ID!, $paymentRequest: String!, $amount: Float!) {
    payInvoice(userId: $userId, paymentRequest: $paymentRequest, amount: $amount) {
      id
      status
    }
  }
`;

const Wallet: React.FC = () => {
    const [invoiceAmount, setInvoiceAmount] = useState('');
    const [invoiceMemo, setInvoiceMemo] = useState('');
    const [paymentRequest, setPaymentRequest] = useState('');
    const [paymentAmount, setPaymentAmount] = useState('');
    const [copyValue, setCopyValue] = useState('');

    const { onCopy, hasCopied } = useClipboard(copyValue);

    const toast = useToast();
    const { user } = useAuth();

    const { data: balanceData, loading: balanceLoading, error: balanceError, refetch: refetchBalance } = useQuery(GET_USER_BALANCE, {
        variables: { userId: user?.id },
        skip: !user?.id,
    });

    const { data: transactionsData, loading: transactionsLoading, error: transactionsError, refetch: refetchTransactions } = useQuery(GET_USER_TRANSACTIONS, {
        variables: { userId: user?.id, limit: 10, offset: 0 },
        skip: !user?.id,
    });

    const [createInvoice, { loading: createInvoiceLoading }] = useMutation(CREATE_INVOICE);
    const [payInvoice, { loading: payInvoiceLoading }] = useMutation(PAY_INVOICE);

    const handleCreateInvoice = async () => {
        if (!user?.id || !invoiceAmount) return;
        try {
            const { data } = await createInvoice({
                variables: { userId: user.id, amount: parseFloat(invoiceAmount), memo: invoiceMemo },
            });
            toast({
                title: 'Invoice Created',
                description: `Invoice ID: ${data.createInvoice.lndInvoiceId}`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            setInvoiceAmount('');
            setInvoiceMemo('');
            refetchBalance();
            refetchTransactions();
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to create invoice',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    const handlePayInvoice = async () => {
        if (!user?.id || !paymentRequest || !paymentAmount) return;
        try {
            await payInvoice({
                variables: { userId: user.id, paymentRequest, amount: parseFloat(paymentAmount) },
            });
            toast({
                title: 'Payment Sent',
                description: 'Invoice paid successfully',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            setPaymentRequest('');
            setPaymentAmount('');
            refetchBalance();
            refetchTransactions();
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to pay invoice',
                status: 'error',
                duration: 9000,
                isClosable: true,
            });
        }
    };

    // Add this conversion function
    const convertSatsToAUD = (sats: number) => {
        const satsToBTC = sats / 100000000; // 1 BTC = 100,000,000 sats
        const BTCToAUD = 79821.14; // Current exchange rate (you might want to fetch this dynamically)
        return (satsToBTC * BTCToAUD).toFixed(2);
    };

    if (!user) {
        return (
            <Box>
                <NavbarWithCallToAction />
                <Container maxW="container.xl" py={8}>
                    <Text>Please log in to access your wallet.</Text>
                </Container>
                <FooterWithFourColumns />
            </Box>
        );
    }

    return (
        <Box>
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={8}>
                <VStack spacing={8} align="stretch">
                    <Heading as="h1" size="2xl">Wallet</Heading>

                    {/* Balance Section */}
                    <Box>
                        <Heading as="h2" size="xl" mb={4}>Balance</Heading>
                        {balanceLoading ? (
                            <Spinner />
                        ) : balanceError ? (
                            <Text color="red.500">Error loading balance</Text>
                        ) : (
                            <Text fontSize="2xl" fontWeight="bold">
                                {balanceData?.getUserBalance.balance} sats
                                (AUD ${convertSatsToAUD(balanceData?.getUserBalance.balance)})
                            </Text>
                        )}
                    </Box>

                    {/* Create Invoice Section */}
                    <Box>
                        <Heading as="h2" size="xl" mb={4}>Create Invoice</Heading>
                        <VStack spacing={4}>
                            <Input
                                placeholder="Amount (sats)"
                                value={invoiceAmount}
                                onChange={(e) => setInvoiceAmount(e.target.value)}
                                type="number"
                            />
                            <Input
                                placeholder="Memo (optional)"
                                value={invoiceMemo}
                                onChange={(e) => setInvoiceMemo(e.target.value)}
                            />
                            <Button
                                onClick={handleCreateInvoice}
                                isLoading={createInvoiceLoading}
                                loadingText="Creating Invoice"
                                colorScheme="blue"
                                width="full"
                            >
                                Create Invoice
                            </Button>
                        </VStack>
                    </Box>

                    {/* Pay Invoice Section */}
                    <Box>
                        <Heading as="h2" size="xl" mb={4}>Pay Invoice</Heading>
                        <VStack spacing={4}>
                            <Input
                                placeholder="Payment Request"
                                value={paymentRequest}
                                onChange={(e) => setPaymentRequest(e.target.value)}
                            />
                            <Input
                                placeholder="Amount (sats)"
                                value={paymentAmount}
                                onChange={(e) => setPaymentAmount(e.target.value)}
                                type="number"
                            />
                            <Button
                                onClick={handlePayInvoice}
                                isLoading={payInvoiceLoading}
                                loadingText="Paying Invoice"
                                colorScheme="green"
                                width="full"
                            >
                                Pay Invoice
                            </Button>
                        </VStack>
                    </Box>

                    {/* Transaction History Section */}
                    <Box>
                        <Heading as="h2" size="xl" mb={4}>Transaction History</Heading>
                        {transactionsLoading ? (
                            <Spinner />
                        ) : transactionsError ? (
                            <Text color="red.500">Error loading transactions</Text>
                        ) : (
                            <Table variant="simple">
                                <Thead>
                                    <Tr>
                                        <Th>Type</Th>
                                        <Th>Amount</Th>
                                        <Th>Status</Th>
                                        <Th>Date</Th>
                                        <Th>Payment Request</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {transactionsData?.getUserTransactions.map((tx: any) => (
                                        <Tr key={tx.id}>
                                            <Td>{tx.type}</Td>
                                            <Td>
                                                {tx.amount} sats
                                                (AUD ${convertSatsToAUD(tx.amount)})
                                            </Td>
                                            <Td>{tx.status}</Td>
                                            <Td>{new Date(parseInt(tx.timestamp)).toLocaleString()}</Td>
                                            <Td>
                                                {tx.type === 'INVOICE' && tx.paymentRequest ? (
                                                    <Tooltip
                                                        label={hasCopied ? "Copied!" : "Click to copy"}
                                                        placement="top"
                                                    >
                                                        <Text
                                                            isTruncated
                                                            maxWidth="200px"
                                                            cursor="pointer"
                                                            onClick={() => {
                                                                setCopyValue(tx.paymentRequest);
                                                                onCopy();
                                                                toast({
                                                                    title: "Copied to clipboard",
                                                                    status: "success",
                                                                    duration: 2000,
                                                                    isClosable: true,
                                                                });
                                                            }}
                                                        >
                                                            {tx.paymentRequest}
                                                        </Text>
                                                    </Tooltip>
                                                ) : (
                                                    'N/A'
                                                )}
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        )}
                    </Box>
                </VStack>
            </Container>
            <FooterWithFourColumns />
        </Box>
    );
};

export default Wallet;