import {
  Box,
  Button,
  ButtonGroup,
  Container,
  HStack,
  useBreakpointValue,
  useDisclosure,
  Text,
  Flex,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Center,
  ModalCloseButton,
  ModalBody,
  VStack,
  ModalFooter,
} from '@chakra-ui/react';
import { Logo } from '../../Logo';
import { MobileDrawer } from './MobileNavbar';
import { ToggleButton } from './ToggleButton';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { FaPhone } from 'react-icons/fa';
import { QuoteModal } from '../../QuoteModal';

export const NavbarWithCallToAction = () => {
  const isDesktop = useBreakpointValue({ base: false, lg: true });
  const mobileNavbar = useDisclosure();
  const quoteModal = useDisclosure();
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  const handleAboutUsClick = () => {
    navigate('/about');
  };

  const handleAuthAction = () => {
    quoteModal.onOpen();
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  return (
    <Box position="sticky" top="0" zIndex="sticky">
      <Box
        borderBottomWidth="1px"
        bg="#d0c9bd"
        boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
        position="relative"
      >
        <Container py="4" maxW="6xl">
          <Flex justify="space-between" align="center">
            <Box onClick={handleLogoClick} cursor="pointer">
              <Logo />
            </Box>
            {isDesktop ? (
              <HStack spacing="8">
                <ButtonGroup size="lg" variant="text" colorScheme="gray" spacing="8">
                  <Button onClick={handleAboutUsClick}>About</Button>
                </ButtonGroup>
                <Button
                  onClick={handleAuthAction}
                  colorScheme="green"
                  size="lg"
                  leftIcon={<FaPhone />}
                >
                  Call Now: 0488 041 641
                </Button>
              </HStack>
            ) : (
              <HStack spacing="4">
                <IconButton
                  icon={<FaPhone />}
                  onClick={handleAuthAction}
                  colorScheme="green"
                  aria-label="Call Now"
                  size="lg"
                />
                <ToggleButton
                  onClick={mobileNavbar.onToggle}
                  isOpen={mobileNavbar.isOpen}
                  aria-label="Open Menu"
                />
              </HStack>
            )}
          </Flex>
        </Container>
      </Box>

      <MobileDrawer isOpen={mobileNavbar.isOpen} onClose={mobileNavbar.onClose} />

      <Modal isOpen={quoteModal.isOpen} onClose={quoteModal.onClose}>
        <ModalOverlay />
        <ModalContent bg="#d0c9bd">
          <ModalHeader p={0}>
            <Center pt={6}>
              <Logo />
            </Center>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4}>
              <Text fontSize="2xl" fontWeight="bold" textAlign="center">Get a Free Quote</Text>
              <Text textAlign="center">
                For a quick chat and a free quote, please call us now on:
              </Text>
              <Button
                leftIcon={<FaPhone />}
                colorScheme="green"
                size="lg"
                width="full"
                onClick={() => window.location.href = 'tel:0488041641'}
              >
                0488 041 641
              </Button>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={quoteModal.onClose} variant="outline">
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};