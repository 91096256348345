// src/pages/Resilience.tsx
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Heading,
    Text,
    VStack,
    List,
    ListItem,
    ListIcon,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Flex,
    Image,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useAuth } from '../contexts/AuthContext';
import { useInviteCode } from '../hooks/useInviteCode';
import { useMutation, gql } from '@apollo/client';




const CREATE_RESILIENCE_PURCHASE = gql`
    mutation CreateResiliencePurchase($userEmail: String!, $amountAUD: Float!, $inviteCode: String) {
        createResiliencePurchase(userEmail: $userEmail, amountAUD: $amountAUD, inviteCode: $inviteCode) {
            id
            status
        }
    }
`;

const UPDATE_RESILIENCE_PURCHASE_STATUS = gql`
    mutation UpdateResiliencePurchaseStatus($id: ID!, $status: ResiliencePurchaseStatus!, $paymentMethod: PaymentMethod) {
        updateResiliencePurchaseStatus(id: $id, status: $status, paymentMethod: $paymentMethod) {
            id
            status
        }
    }
`;

const Resilience = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inviteCode, setInviteCode] = useState('');
    const [donationAmount, setDonationAmount] = useState(33);
    const [purchaseId, setPurchaseId] = useState('');
    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    useInviteCode();
    const { user, isLoading } = useAuth();

    const [createResiliencePurchase] = useMutation(CREATE_RESILIENCE_PURCHASE);
    const [updateResiliencePurchaseStatus] = useMutation(UPDATE_RESILIENCE_PURCHASE_STATUS);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('inviteCode');
        if (code) {
            setInviteCode(code);
            navigate(location.pathname, { replace: true });
        }
    }, [location, navigate]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleEnrollClick = async () => {
        if (user) {
            try {
                const { data } = await createResiliencePurchase({
                    variables: {
                        userEmail: user.email,
                        amountAUD: donationAmount,
                        inviteCode: inviteCode || undefined,
                    },
                });
                setPurchaseId(data.createResiliencePurchase.id);
                setIsModalOpen(true);
            } catch (error) {
                console.error('Error creating resilience purchase:', error);
                toast({
                    title: "Error",
                    description: "Failed to initiate enrollment. Please try again.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            navigate('/login', { state: { from: location } });
        }
    };


    const handlePayment = async () => {
        try {
            await updateResiliencePurchaseStatus({
                variables: {
                    id: purchaseId,
                    status: "COMPLETED",
                    paymentMethod: "BITCOIN", // or "BANK_TRANSFER" based on user selection
                },
            });
            setIsModalOpen(false);
            toast({
                title: "Enrollment Successful",
                description: `You've successfully enrolled in the 1080 Resilience and Self-Governance program! Invite Code: ${inviteCode || 'N/A'}`,
                status: "success",
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error updating resilience purchase status:', error);
            toast({
                title: "Error",
                description: "Failed to confirm payment. Please contact support.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    if (isLoading) {
        return <Box>Loading...</Box>;
    }

    return (
        <Box>
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={8}>
                <VStack spacing={8} align="stretch">
                    <Flex direction={{ base: 'column', md: 'row' }} align="center" justify="space-between">
                        <Box flex={1}>
                            <Heading as="h1" size="2xl">1080 Resilience and Self-Governance</Heading>
                            <Heading as="h2" size="xl" mt={2}>Unlock Your Inner Power</Heading>
                        </Box>
                        <Box flex={1} ml={{ base: 0, md: 8 }} mt={{ base: 4, md: 0 }}>
                            <Image
                                src="/product_images/1080.png"
                                alt="1080 Resilience and Self-Governance"
                                maxWidth="100%"
                                borderRadius="md"
                                boxShadow="lg"
                            />
                        </Box>
                    </Flex>
                    {inviteCode && (
                        <Text fontSize="lg" color="green.500">
                            Invite Code Applied: {inviteCode}
                        </Text>
                    )}

                    <Text fontSize="xl">
                        Are you feeling overwhelmed by the chaos of modern life? Does it seem like every day brings new challenges, from personal struggles to global crises?
                    </Text>

                    <Text fontSize="lg">
                        You're not alone. Millions are grappling with:
                    </Text>
                    <List spacing={2}>
                        <ListItem>
                            <ListIcon as={CheckCircleIcon} color="red.500" />
                            Crushing stress and anxiety
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckCircleIcon} color="red.500" />
                            Family tensions and breakdowns
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckCircleIcon} color="red.500" />
                            Financial insecurity and job instability
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckCircleIcon} color="red.500" />
                            Distrust in government and institutions
                        </ListItem>
                        <ListItem>
                            <ListIcon as={CheckCircleIcon} color="red.500" />
                            Fear about global conflicts and environmental threats
                        </ListItem>
                    </List>

                    <Text fontSize="lg">
                        These pressures can leave you feeling powerless, trapped, and uncertain about the future. But what if you could transform these challenges into opportunities for growth?
                    </Text>

                    <Text fontSize="lg" fontWeight="bold">
                        The world isn't slowing down. Each day you wait is another day lost to worry and inaction. You need tools to thrive, not just survive, in these turbulent times.
                    </Text>

                    <Box>
                        <Heading as="h2" size="xl" mb={4}>Introducing "1080 Resilience and Self-Governance"</Heading>
                        <Text fontSize="lg">Your Monthly Guide to Inner Strength and Building Your Resilient Community</Text>

                        <Text mt={4}>
                            This isn't just another self-help program. It's a transformative journey blending ancient wisdom with modern science:
                        </Text>
                        <List spacing={3} mt={2}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Learn evidence-based techniques for stress management and emotional regulation
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Develop unshakeable confidence and adaptability
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Cultivate harmonious relationships in family and community
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Explore the Hermetic Principles and The Kybalion's timeless teachings
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Build practical skills for financial and personal independence
                            </ListItem>
                        </List>
                    </Box>

                    <Box>
                        <Heading as="h3" size="lg" mb={4}>Each month, you'll gain:</Heading>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="blue.500" />
                                In-depth lessons on resilience and self-governance
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="blue.500" />
                                Practical exercises to apply these principles in daily life
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="blue.500" />
                                Community support from your peer participants
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="blue.500" />
                                Tools to navigate personal, professional, and global challenges with grace
                            </ListItem>
                        </List>
                    </Box>

                    <Box>
                        <Heading as="h2" size="xl" mb={4}>Pay what you feel for a Stronger You</Heading>
                        <Text fontSize="lg" mb={4}>
                            This program is by donation so that anyone may attend. Suggested donation is just 0.00033 bitcoin or $33 AUD per month. Today you're not buying a course – you're investing in a new way of living. Choose how much you feel inspired to invest in upgrading your life for a future on your terms in joy, freedom, and respect for All.
                        </Text>
                    </Box>

                    <Box>
                        <Heading as="h3" size="lg" mb={4}>Imagine facing each day with:</Heading>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="purple.500" />
                                A profound sense of inner peace
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="purple.500" />
                                Confidence to tackle any challenge
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="purple.500" />
                                The ability to create positive change in your life and community
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="purple.500" />
                                Freedom from the anxieties and constraints that once held you back
                            </ListItem>
                        </List>
                    </Box>

                    <Text fontSize="lg" fontWeight="bold">
                        Don't let another day pass feeling unprepared and overwhelmed. Your journey to mastering life's challenges starts now.
                    </Text>

                    <Button colorScheme="blue" size="lg" onClick={handleEnrollClick}>
                        ENROLL TODAY
                    </Button>

                    <Text fontSize="sm">
                        (minimum start requirement = $2,400)
                    </Text>

                    <Text fontSize="lg" fontWeight="bold">
                        Transform your mindset. Empower your life. Shape your destiny. Nurture your community.
                    </Text>

                    <Text fontSize="md">
                        Earn affiliate value in bitcoin for every friend you bring to the "marketplace".
                    </Text>
                </VStack>
            </Container>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Enroll in 1080 Resilience and Self-Governance</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text mb={4}>Choose your monthly donation amount:</Text>
                        <NumberInput value={donationAmount} onChange={(_, value) => setDonationAmount(value)} min={1}>
                            <NumberInputField />
                            <NumberInputStepper>
                                <NumberIncrementStepper />
                                <NumberDecrementStepper />
                            </NumberInputStepper>
                        </NumberInput>
                        <Text mt={4}>To complete your enrollment, please send {donationAmount} AUD worth of Bitcoin to the following address:</Text>
                        <Text fontWeight="bold" mt={2}>bc1qxy2kgdygjrsqtzq2n0yrf2493p83kkfjhx0wlh</Text>
                        <Text mt={4}>Or make a bank transfer to:</Text>
                        <Text>BSB: 062-692</Text>
                        <Text>Account Number: 41168430</Text>
                        <Text mt={4}>Once payment is confirmed, you'll receive an email with program access details.</Text>
                        {!inviteCode && (
                            <Box mt={4}>
                                <Text mb={2}>If you have an invite code, please enter it here:</Text>
                                <Input
                                    placeholder="Enter invite code"
                                    value={inviteCode}
                                    onChange={(e) => setInviteCode(e.target.value)}
                                />
                            </Box>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handlePayment}>
                            I've Sent the Payment
                        </Button>
                        <Button variant="ghost" onClick={() => setIsModalOpen(false)}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <FooterWithFourColumns />
        </Box>
    );
};

export default Resilience;