import React from 'react';
import { useQuery, gql } from '@apollo/client';
import {
    Box,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Heading,
    Text,
    Spinner,
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

const GET_ALL_USERS = gql`
  query GetAllUsers {
    getAllUsers {
      id
      email
      firstName
      lastName
      phoneNumber
      issuer
      publicAddress
      isMfaEnabled
      addressETH
      inviteCode
      membershipLevel
      invitedBy {
        id
        email
      }
    }
  }
`;

const AllUsers = () => {
    const { loading, error, data } = useQuery(GET_ALL_USERS);

    if (loading) return (
        <Box>
            <NavbarWithCallToAction />
            <Box maxWidth="1200px" margin="auto" padding={8}>
                <Spinner />
            </Box>
            <FooterWithFourColumns />
        </Box>
    );

    if (error) return (
        <Box>
            <NavbarWithCallToAction />
            <Box maxWidth="1200px" margin="auto" padding={8}>
                <Text>Error: {error.message}</Text>
            </Box>
            <FooterWithFourColumns />
        </Box>
    );

    if (!data || !data.getAllUsers) return (
        <Box>
            <NavbarWithCallToAction />
            <Box maxWidth="1200px" margin="auto" padding={8}>
                <Text>No user data available</Text>
            </Box>
            <FooterWithFourColumns />
        </Box>
    );

    return (
        <Box>
            <NavbarWithCallToAction />
            <Box maxWidth="1200px" margin="auto" padding={8}>
                <Heading as="h1" size="xl" marginBottom={6}>
                    All Users
                </Heading>
                <TableContainer>
                    <Table variant="simple">
                        <TableCaption>All users in the system</TableCaption>
                        <Thead>
                            <Tr>
                                <Th>ID</Th>
                                <Th>Email</Th>
                                <Th>First Name</Th>
                                <Th>Last Name</Th>
                                <Th>Phone Number</Th>
                                <Th>Issuer</Th>
                                <Th>Public Address</Th>
                                <Th>MFA Enabled</Th>
                                <Th>ETH Address</Th>
                                <Th>Invite Code</Th>
                                <Th>Membership Level</Th>
                                <Th>Invited By</Th>

                            </Tr>
                        </Thead>
                        <Tbody>
                            {data.getAllUsers.map((user: any) => (
                                <Tr key={user.id}>
                                    <Td>{user.id}</Td>
                                    <Td>{user.email}</Td>
                                    <Td>{user.firstName}</Td>
                                    <Td>{user.lastName}</Td>
                                    <Td>{user.phoneNumber}</Td>
                                    <Td>{user.issuer}</Td>
                                    <Td>{user.publicAddress}</Td>
                                    <Td>{user.isMfaEnabled ? 'Yes' : 'No'}</Td>
                                    <Td>{user.addressETH}</Td>
                                    <Td>{user.inviteCode}</Td>
                                    <Td>{user.membershipLevel}</Td>
                                    <Td>{user.invitedBy ? user.invitedBy.email : 'N/A'}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </Box>
            <FooterWithFourColumns />
        </Box>
    );
};

export default AllUsers;