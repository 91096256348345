// src/pages/Relationships.tsx
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Heading,
    Text,
    VStack,
    List,
    ListItem,
    ListIcon,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    Flex,
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useAuth } from '../contexts/AuthContext';
import QRCode from 'qrcode.react';
import InviteLink from '../components/InviteLink';
import { useInviteCode } from '../hooks/useInviteCode';

const Relationships = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inviteCode, setInviteCode] = useState('');
    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    useInviteCode(); // Add this line to handle invite codes
    const { user, isLoading } = useAuth();


    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('inviteCode');
        if (code) {
            setInviteCode(code);
            navigate(location.pathname, { replace: true });
        }
    }, [location, navigate]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handlePayment = () => {
        setIsModalOpen(false);
        toast({
            title: "Payment Successful",
            description: `You've successfully enrolled in the course! Invite Code: ${inviteCode || 'N/A'}`,
            status: "success",
            duration: 5000,
            isClosable: true,
        });
    };

    const generateInviteUrl = () => {
        const baseUrl = window.location.origin;
        return `${baseUrl}/relationships?inviteCode=${user?.inviteCode || ''}`;
    };

    // const inviteUrl = user && user.inviteCode ? generateInviteUrl() : null;

    const inviteUrl = user?.inviteCode

    if (isLoading) {
        return <Box>Loading...</Box>;
    }
    return (
        <Box>
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={8}>
                <VStack spacing={8} align="stretch">
                    <Heading as="h1" size="2xl">Transform Your Relationship: The Aussie Couple's Guide to Lasting Love</Heading>

                    {inviteCode && (
                        <Text fontSize="lg" color="green.500">
                            Invite Code Applied: {inviteCode}
                        </Text>
                    )}

                    <Text fontSize="xl">
                        Are you ready to build a stronger, more fulfilling relationship that stands the test of time? Our comprehensive course, based on the groundbreaking Gottman Method, is designed specifically for Australian couples who are committed to nurturing their love and avoiding the devastating emotional and financial costs of divorce.
                    </Text>

                    <Box>
                        <Heading as="h2" size="xl" mb={4}>What You'll Get:</Heading>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                8 weeks of intensive, evidence-based relationship training
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Weekly live online group sessions with a certified Gottman-trained therapist
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Practical tools and exercises to improve communication and intimacy
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Access to a supportive community of like-minded couples
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Lifetime access to course materials and updates
                            </ListItem>
                        </List>
                    </Box>

                    <Box>
                        <Heading as="h2" size="xl" mb={4}>Why Choose Our Course:</Heading>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Backed by 40+ years of relationship research
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Proven to significantly improve relationship satisfaction
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Tailored for Australian couples and culture
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                More effective than traditional counseling
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Cheaper than a single session with many couple's therapists
                            </ListItem>
                        </List>
                    </Box>

                    <Box>
                        <Heading as="h2" size="xl" mb={4}>Investment: $690 AUD</Heading>
                        <Text fontSize="lg" mb={4}>
                            🎁 Bonus: Sign up now and receive our exclusive "Date Night Ideas for Aussie Couples" ebook ($49 value) absolutely free!
                        </Text>
                        <Button colorScheme="blue" size="lg" onClick={() => setIsModalOpen(true)}>
                            Enroll Now with Bitcoin Lightning
                        </Button>
                    </Box>



                    {/* {user && <InviteLink />} */}
                </VStack>
            </Container>

            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Bitcoin Lightning Payment</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>To complete your enrollment, please send 690 AUD worth of Bitcoin to the following Lightning address:</Text>
                        <Text fontWeight="bold" mt={2}>lnbc1234567890...</Text>
                        <Text mt={4}>Once payment is confirmed, you'll receive an email with course access details.</Text>
                        {!inviteCode && (
                            <Box mt={4}>
                                <Text mb={2}>If you have an invite code, please enter it here:</Text>
                                <Input
                                    placeholder="Enter invite code"
                                    value={inviteCode}
                                    onChange={(e) => setInviteCode(e.target.value)}
                                />
                            </Box>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handlePayment}>
                            I've Sent the Payment
                        </Button>
                        <Button variant="ghost" onClick={() => setIsModalOpen(false)}>Cancel</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <FooterWithFourColumns />
        </Box>
    );
};

export default Relationships;