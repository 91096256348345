import React from 'react';
import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Container,
  Divider,
  Icon,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { FaGithub, FaLinkedin, FaTwitter, FaInstagram, FaPhone, FaCode, FaFire } from 'react-icons/fa'
import { Logo } from '../../Logo'
import { links } from './_data'
import InviteLink from '../../../components/InviteLink';

export const FooterWithFourColumns = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Box bg="#d0c9bd" >
      {/* <InviteLink /> */}
      <Container as="footer" role="contentinfo" maxW="6xl" >
        <Stack
          justify="space-between"
          align="start"
          direction={{ base: 'column', lg: 'row' }}
          py={{ base: '12', md: '16' }}
          spacing="4"
        >

          <Stack spacing={{ base: '3', md: '8' }} align="start">

            <Logo />
            <Box maxW="60%">
              <Text fontSize="xl" fontWeight="bold" mb={6} color="gray">
                Building your dreams, one project at a time. Expert carpentry and construction services for residential and commercial projects.
              </Text>


            </Box>

          </Stack>
          <SimpleGrid columns={{ base: 2, md: 4 }} gap="8" width={{ base: 'full', lg: 'auto' }}>
            {links.map((group, idx) => (
              <Stack key={idx} spacing="4" minW={{ lg: '40' }}>
                {/* <Text fontSize="sm" fontWeight="semibold" color="fg.subtle">
                {group.title}
              </Text> */}
                <Stack spacing="3" shouldWrapChildren>
                  {group.links.map((link, idx) => (
                    <Button key={idx} as="a" variant="text" colorScheme="gray" href={link.href}>
                      {link.label}
                    </Button>
                  ))}
                </Stack>
              </Stack>
            ))}
          </SimpleGrid>
        </Stack>
        <Divider />
        <Stack
          pt="8"
          pb="12"
          justify="space-between"
          direction={{ base: 'column-reverse', md: 'row' }}
          align="center"
        >
          <Text fontSize="sm" color="fg.subtle">
            &copy; {new Date().getFullYear()} SJT Constructions | All rights reserved.
          </Text>
          {/* <Badge colorScheme='purple'>Concept Website Only - Not in Production</Badge> */}
          <ButtonGroup variant="tertiary">

            {/* <IconButton as="a" href="#" aria-label="LinkedIn" icon={<FaLinkedin />} /> */}
            {/* <IconButton as="a" href="#" aria-label="GitHub" icon={<FaGithub />} /> */}
            <IconButton
              as="a"
              href="https://www.instagram.com/sjt_constructions/"
              aria-label="Instagram"
              icon={<FaInstagram />}
              target="_blank"
              rel="noopener noreferrer"
            />

          </ButtonGroup>
        </Stack>
        {/* Developer Information */}
        <Stack align="center" pb="4">
          <Button
            leftIcon={<FaFire />}
            size="xs"  // Changed from "sm" to "xs" for the smallest size
            variant="outline"
            onClick={onOpen}
            fontSize="xs"  // Added to make the text smaller
            py={1}  // Reduced padding vertically
            px={2}  // Reduced padding horizontally
          >
            Built with <Icon as={FaFire} color="orange.500" /> by Thomas Miller
          </Button>
        </Stack>
      </Container>



      {/* Modal for Developer Contact */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Need a Website Like This?</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              This site was crafted with care by Thomas Miller. If you're interested in a similar website for your business, don't hesitate to get in touch!
            </Text>
            <Button
              leftIcon={<FaPhone />}
              colorScheme="blue"
              mt={4}
              onClick={() => window.location.href = 'tel:0458944327'}
            >
              Call Thomas Miller
            </Button>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>

  )


}
