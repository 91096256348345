import React from "react";
import { ChakraProvider, theme, Spinner, Center } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ApolloProvider } from "@apollo/client";
import Home from "./pages/Home";
import Login from "./pages/Login";
import { AuthProvider, useAuth } from "./contexts/AuthContext";
import BuyBitcoin from "./pages/BuyBitcoin";
import Profile from "./pages/Profile";
import { Metrics } from "./pages/Metrics";
import Earn from "./pages/Earn";
import Marketplace from "./pages/Marketplace";
import MyBTCPurchases from "./pages/MyBTCPurchases";
import News from "./pages/News";
import Relationships from './pages/Relationships';
import { client } from "./contexts/AuthContext";
import AllUsers from "./pages/AllUsers";
import ProfileDetails from "./pages/ProfileDetails";
import AddProduct from "./pages/AddProduct";
import Resilience from "./pages/Resilience";
import LandPurchaseCanungra from "./pages/LandPurchaseCanungra";
import Wallet from "./pages/Wallet";
import GroupBeefBuy from "./pages/GroupBeefBuy";
import CreateCampaign from "./pages/CreateCampaing";
import Campaigns from "./pages/Campaigns";
import AboutUs from "./pages/AboutUs";
import DeckMaintenance from "./pages/DeckMaintenance";
import SpecialtyBuilds from "./pages/SpecialtyBuilds";



const AppContent = () => {
  const { isLoading } = useAuth();

  if (isLoading) {
    return (
      <Center height="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/buybitcoin" element={<BuyBitcoin />} />
      <Route path="/profile" element={<Profile />} />
      <Route path="/metrics" element={<Metrics />} />
      <Route path="/earn" element={<Earn />} />
      <Route path="/marketplace" element={<Marketplace />} />
      <Route path="/news" element={<News />} />
      <Route path="/mybtcpurchases" element={<MyBTCPurchases />} />
      <Route path="/relationships" element={<Relationships />} />
      <Route path="/allusers" element={<AllUsers />} />
      <Route path="/profiledetails" element={<ProfileDetails />} />
      <Route path="/addproduct" element={<AddProduct />} />
      <Route path="/resilience" element={<Resilience />} />
      <Route path="/LandPurchaseCanungra" element={<LandPurchaseCanungra />} />
      <Route path="/wallet" element={<Wallet />} />
      <Route path="/brisbanegroupbeefbuy" element={<GroupBeefBuy />} />
      <Route path="/createcampaign" element={<CreateCampaign />} />
      <Route path="/campaigns" element={<Campaigns />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/decks" element={<DeckMaintenance />} />
      <Route path="/specialtybuilds" element={<SpecialtyBuilds />} />
      
    </Routes>
  );
};

export const App = () => (
  <Router>
    <ApolloProvider client={client}>
      <AuthProvider>
        <ChakraProvider theme={theme}>
          <AppContent />
        </ChakraProvider>
      </AuthProvider>
    </ApolloProvider>
  </Router>
);