// src/pages/CreateCampaign.tsx

import React, { useState } from 'react';
import { useMutation, gql } from '@apollo/client';
import {
    Box,
    Button,
    Container,
    FormControl,
    FormLabel,
    Heading,
    Input,
    Textarea,
    VStack,
    useToast,
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

const CREATE_GROUP_PURCHASE_CAMPAIGN = gql`
  mutation CreateGroupPurchaseCampaign($name: String!, $description: String!, $targetAmount: Float!) {
    createGroupPurchaseCampaign(name: $name, description: $description, targetAmount: $targetAmount) {
      id
      name
      description
      targetAmount
      currentAmount
      pledgeCount
      status
    }
  }
`;

const CreateCampaign = () => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [targetAmount, setTargetAmount] = useState('');
    const toast = useToast();

    const [createCampaign, { loading }] = useMutation(CREATE_GROUP_PURCHASE_CAMPAIGN);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        try {
            const { data } = await createCampaign({
                variables: {
                    name,
                    description,
                    targetAmount: parseFloat(targetAmount),
                },
            });
            toast({
                title: 'Campaign created',
                description: `Campaign ID: ${data.createGroupPurchaseCampaign.id}`,
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            // Reset form
            setName('');
            setDescription('');
            setTargetAmount('');
        } catch (error) {
            toast({
                title: 'Error',
                description: 'Failed to create campaign',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    return (
        <Box minHeight="100vh" display="flex" flexDirection="column">
            <NavbarWithCallToAction />
            <Container maxW="container.md" flex="1" py={8}>
                <VStack spacing={8} align="stretch">
                    <Heading as="h1" size="xl">Create New Campaign</Heading>
                    <form onSubmit={handleSubmit}>
                        <VStack spacing={4} align="stretch">
                            <FormControl isRequired>
                                <FormLabel>Campaign Name</FormLabel>
                                <Input
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    placeholder="Enter campaign name"
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Description</FormLabel>
                                <Textarea
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    placeholder="Enter campaign description"
                                />
                            </FormControl>
                            <FormControl isRequired>
                                <FormLabel>Target Amount</FormLabel>
                                <Input
                                    type="number"
                                    value={targetAmount}
                                    onChange={(e) => setTargetAmount(e.target.value)}
                                    placeholder="Enter target amount"
                                />
                            </FormControl>
                            <Button type="submit" colorScheme="blue" isLoading={loading}>
                                Create Campaign
                            </Button>
                        </VStack>
                    </form>
                </VStack>
            </Container>
            <FooterWithFourColumns />
        </Box>
    );
};

export default CreateCampaign;