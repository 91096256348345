import React from 'react';
import { useQuery, gql } from '@apollo/client';
import {
    Box,
    Container,
    Heading,
    Text,
    VStack,
    SimpleGrid,
    Button,
    Image,
    useToast,
    Spinner,
} from '@chakra-ui/react';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useNavigate } from 'react-router-dom';

const GET_ALL_CAMPAIGNS = gql`
  query GetAllCampaigns {
    getAllCampaigns {
      id
      title
      description
      imageUrl
      targetAmount
      currentAmount
      endDate
    }
  }
`;

const Campaigns: React.FC = () => {
    const { loading, error, data } = useQuery(GET_ALL_CAMPAIGNS);
    const toast = useToast();
    const navigate = useNavigate();

    if (loading) return <Spinner />;
    if (error) {
        console.error('Error fetching campaigns:', error);
        return <Text>Error loading campaigns. Please try again later.</Text>;
    }

    const handleCampaignClick = (campaignId: string) => {
        navigate(`/campaign/${campaignId}`);
    };

    return (
        <Box>
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={8}>
                <VStack spacing={8} align="stretch">
                    <Heading as="h1" size="2xl" textAlign="center">
                        Current Campaigns
                    </Heading>
                    <Text fontSize="xl" textAlign="center">
                        Join these campaigns and make a difference!
                    </Text>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={10}>
                        {data.getAllCampaigns.map((campaign: any) => (
                            <Box
                                key={campaign.id}
                                borderWidth={1}
                                borderRadius="lg"
                                overflow="hidden"
                                boxShadow="md"
                            >
                                <Image src={campaign.imageUrl} alt={campaign.title} />
                                <Box p={6}>
                                    <Heading as="h3" size="lg" mb={2}>
                                        {campaign.title}
                                    </Heading>
                                    <Text mb={4}>{campaign.description}</Text>
                                    <Text fontWeight="bold" mb={2}>
                                        Progress: {campaign.currentAmount} / {campaign.targetAmount} sats
                                    </Text>
                                    <Text mb={4}>
                                        Ends on: {new Date(campaign.endDate).toLocaleDateString()}
                                    </Text>
                                    <Button
                                        colorScheme="blue"
                                        onClick={() => handleCampaignClick(campaign.id)}
                                    >
                                        View Campaign
                                    </Button>
                                </Box>
                            </Box>
                        ))}
                    </SimpleGrid>
                </VStack>
            </Container>
            <FooterWithFourColumns />
        </Box>
    );
};

export default Campaigns;