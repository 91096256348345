import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    FormControl,
    FormLabel,
    Input,
    VStack,
    Heading,
    useToast,
    Flex,
    Text,
} from '@chakra-ui/react';
import { useAuth } from '../contexts/AuthContext';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { ApolloClient, InMemoryCache, gql, useQuery, useMutation } from '@apollo/client';
import { getApiUrl } from '../utils/helpers';
import { Magic } from 'magic-sdk';
import { MagicUserMetadata } from 'magic-sdk';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';



const magic = new Magic('pk_live_9B9AD495258E9C9F');

const client = new ApolloClient({
    uri: getApiUrl(),
    cache: new InMemoryCache()
});


const GET_USER = gql`
  query GetUser($email: String!) {
    getUserByEmail(email: $email) {
      id
      email
      firstName
      lastName
      phoneNumber
      issuer
      publicAddress
      isMfaEnabled
      addressETH
      permissions
      membershipLevel
      invitedBy
      inviteCode
    }
  }
`;

// const CREATE_USER = gql`
//   mutation CreateUser($input: UserInput!) {
//     createUser(input: $input) {
//       id
//       email
//       firstName
//       lastName
//       phoneNumber
//       issuer
//       publicAddress
//       isMfaEnabled
//       invitedBy {
//         email
//       }
//       inviteCode
//     }
//   }
// `;

const UPDATE_USER = gql`
  mutation UpdateUser($id: ID!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      id
      email
      firstName
      lastName
      phoneNumber
      issuer
      publicAddress
      isMfaEnabled
      addressETH
      permissions
      membershipLevel
      invitedBy {
        email
      }
      inviteCode
    }
  }
`;

const Profile = () => {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { user, setUser } = useAuth();
    const [firstName, setFirstName] = useState(user?.firstName || '');
    const [lastName, setLastName] = useState(user?.lastName || '');
    const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || '');
    const toast = useToast();
    const [magicMetadata, setMagicMetadata] = useState<MagicUserMetadata | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [newInviteCode, setNewInviteCode] = useState('');


    const { loading, error, data, refetch } = useQuery(GET_USER, {
        variables: { email: user?.email },
        skip: !user?.email,
    });

    const [updateUser] = useMutation(UPDATE_USER);

    useEffect(() => {
        if (data && data.getUserByEmail) {
            setUser(data.getUserByEmail);
            setFirstName(data.getUserByEmail.firstName || '');
            setLastName(data.getUserByEmail.lastName || '');
            setPhoneNumber(data.getUserByEmail.phoneNumber || '');
        }
    }, [data, setUser]);


    useEffect(() => {
        // Print the user object from the useAuth context
        console.log('User object:', user);
        console.log(user);
        console.log(user?.email);
        console.log(user?.firstName);
        setFirstName(user?.firstName || '');
    }, [user]);


    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!user?.email) return;

        const input = {
            email: user.email,
            firstName,
            lastName,
            phoneNumber,
            inviteCode: newInviteCode || user?.inviteCode,
        };

        try {
            const { data } = await updateUser({
                variables: { id: user.id, input }
            });
            setUser(data.updateUser);
            setNewInviteCode('');
            toast({
                title: 'Profile updated',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });
            
            // Refetch user data to ensure we have the latest information
            await refetch();
        } catch (error) {
            // console.error('Error updating profile:', error);
            // toast({
            //     title: 'Error',
            //     description: 'Failed to update profile. Please try again.',
            //     status: 'error',
            //     duration: 5000,
            //     isClosable: true,
            // });
        }
    };

    return (
        <Flex direction="column" minHeight="100vh">
            <NavbarWithCallToAction />
            <Box flex={1} padding={8}>
                <VStack spacing={8} width="full" maxWidth="500px" margin="auto">
                    <Heading>{user?.id ? 'Update Profile' : 'Create Profile'}</Heading>

                    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
                        <FormControl id="firstName" marginBottom={4}>
                            <FormLabel>First Name</FormLabel>
                            <Input
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </FormControl>
                        <FormControl id="lastName" marginBottom={4}>
                            <FormLabel>Last Name</FormLabel>
                            <Input
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </FormControl>
                        <FormControl id="phoneNumber" marginBottom={4}>
                            <FormLabel>Phone Number</FormLabel>
                            <Input
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </FormControl>

                        <FormControl id="newInviteCode" marginBottom={4}>
                            <FormLabel>New Invite Code (optional)</FormLabel>
                            <Input
                                value={newInviteCode}
                                onChange={(e) => setNewInviteCode(e.target.value)}
                                placeholder="Enter new invite code"
                            />
                        </FormControl>

                        <Button type="submit" colorScheme="blue" width="full">
                            {user?.id ? 'Update Profile' : 'Create Profile'}
                        </Button>
                    </form>


                    <Box width="100%" mt={8}>
                        {user && (
                            <Box width="100%" mt={8}>
                                <Heading size="md" mb={4}>All User Object Data</Heading>
                                <Text><strong>Email:</strong> {user.email}</Text>
                                <Text><strong>Issuer:</strong> {user.issuer}</Text>
                                <Text><strong>Public Address:</strong> {user.publicAddress}</Text>
                                <Text><strong>MFA Enabled:</strong> {user.isMfaEnabled ? 'Yes' : 'No'}</Text>
                                <Text><strong>Address ETH:</strong> {user.addressETH || 'N/A'}</Text>
                                <Text><strong>Permissions:</strong> {user.permissions?.join(', ') || 'N/A'}</Text>
                                <Text><strong>Membership Level:</strong> {user.membershipLevel || 'N/A'}</Text>
                                {/* <Text><strong>Invited By:</strong> {user.invitedBy || 'N/A'}</Text> */}
                                <Text><strong>Invite Code:</strong> {user.inviteCode || 'N/A'}</Text>
                            </Box>
                        )}
                    </Box>


                    <Box width="100%" mt={8}>
                        <ReactRouterLink to="/mybtcpurchases" color="blue.500">
                            View My Bitcoin Purchases
                        </ReactRouterLink>
                    </Box>

                </VStack>
            </Box>
            <FooterWithFourColumns />
        </Flex>
    );
};

export default Profile;