import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Container,
    Heading,
    Text,
    VStack,
    List,
    ListItem,
    ListIcon,
    useToast,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Input,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
    NumberIncrementStepper,
    NumberDecrementStepper,
    Textarea,
    Flex,
    Image,
    AspectRatio,
    Checkbox,
    Center,
    Link,
    Spacer
} from '@chakra-ui/react';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useAuth } from '../contexts/AuthContext';
import { useInviteCode } from '../hooks/useInviteCode';
import { useMutation, gql } from '@apollo/client';
import YouTube from 'react-youtube';

const CREATE_LAND_PURCHASE_PLEDGE = gql`
    mutation CreateLandPurchasePledge($userEmail: String!, $pledgeAmount: Float!, $comments: String) {
        createLandPurchasePledge(userEmail: $userEmail, pledgeAmount: $pledgeAmount, comments: $comments) {
            id
            status
        }
    }
`;

const LandPurchaseCanungra = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [inviteCode, setInviteCode] = useState('');
    const [pledgeAmount, setPledgeAmount] = useState(29629.62);
    const [comments, setComments] = useState('');
    const [pledgeId, setPledgeId] = useState('');
    const [agreeToTerms, setAgreeToTerms] = useState(false);
    const toast = useToast();
    const location = useLocation();
    const navigate = useNavigate();
    useInviteCode();
    const { user, isLoading } = useAuth();

    const [createLandPurchasePledge] = useMutation(CREATE_LAND_PURCHASE_PLEDGE);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const code = searchParams.get('inviteCode');
        if (code) {
            setInviteCode(code);
            navigate(location.pathname, { replace: true });
        }
    }, [location, navigate]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handlePledgeSubmit = async () => {
        if (!agreeToTerms) {
            toast({
                title: "Agreement Required",
                description: "Please agree to the terms before submitting your pledge.",
                status: "warning",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        if (user) {
            try {
                const { data } = await createLandPurchasePledge({
                    variables: {
                        userEmail: user.email,
                        pledgeAmount,
                        comments,
                    },
                });
                setPledgeId(data.createLandPurchasePledge.id);
                setIsModalOpen(true);
            } catch (error) {
                console.error('Error creating land purchase pledge:', error);
                toast({
                    title: "Error",
                    description: "Failed to submit pledge. Please try again.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
        } else {
            navigate('/login', { state: { from: location } });
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        toast({
            title: "Pledge Submitted",
            description: "Thank you for your interest. We'll be in contact shortly.",
            status: "success",
            duration: 5000,
            isClosable: true,
        });
    };

    if (isLoading) {
        return <Box>Loading...</Box>;
    }

    const handleLearnMore = () => {
        navigate('/login', { state: { from: location } });
    };

    return (
        <Box>
            <NavbarWithCallToAction />
            <Container maxW="container.xl" py={8}>
                <VStack spacing={8} align="stretch">
                    <Flex direction={{ base: 'column', md: 'row' }} align="center" justify="space-between">
                        <Box flex={1}>
                            <Heading as="h1" size="2xl">Simulated Group Buy 55.74 acres in Canungra</Heading>
                            <Heading as="h2" size="xl" mt={2}>Expression of Interest</Heading>
                        </Box>
                        <Box flex={1} ml={{ base: 0, md: 8 }} mt={{ base: 4, md: 0 }}>
                            <Image
                                src="/product_images/canungra.png"
                                alt="10 Acres of Land"
                                maxWidth="100%"
                                borderRadius="md"
                                boxShadow="lg"
                            />
                        </Box>
                    </Flex>

                    <Text fontSize="xl">
                        Join us in a simulated group purchase of 10 acres of land. This is an opportunity to explore co-ownership and community building without immediate financial commitment.
                    </Text>

                    <Box>
                        <Heading as="h2" size="xl" mb={4}>About the Property</Heading>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Location: 69 Regal Drive, Canungra, Qld 4275
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Size: 22.56ha (55.74697 acres)
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Current Bid: $600,000 (currently 5 Qualified Bidders)
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Additional $200,000 for workman's shed and equipment
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="green.500" />
                                Total: $800,000 / 20 people = $40,000 AUD per lot
                            </ListItem>
                        </List>
                    </Box>


                    {/* YouTube Video Embed */}
                    <Box>
                        <Heading as="h2" size="xl" mb={4}>Learn More About Our Vision</Heading>
                        <AspectRatio ratio={16 / 9}>
                            <YouTube videoId="AyDjKMEB-Hw" opts={{ width: '100%', height: '100%' }} />
                        </AspectRatio>
                    </Box>

                    <Box>
                        <Heading as="h2" size="xl" mb={4}>Key Points</Heading>
                        <List spacing={3}>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="blue.500" />
                                Each participant is entitled to a 2-acre lot, with an additional 11.74697 acres reserved as communal land. The two land managers (Sun and Tom) each receive 2 acres as compensation for overseeing this process and implementing a <Link href="https://coasys.org/" isExternal>peer-to-peer digital governance infrastructure</Link>. This arrangement serves as their fee for handling the groundwork and management of the project.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="blue.500" />
                                No subdivision for 10 years (or unless p2p governence is in place) to save on costs and time and simplify the understand and process.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="blue.500" />
                                Community land trust or memorandum of understanding or obligations, template coming soon.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="blue.500" />
                                Focus on temporary structures and camping. once digital governence is setup we can take on development applications via the digital govenance system.
                            </ListItem>
                            <ListItem>
                                <ListIcon as={CheckCircleIcon} color="blue.500" />
                                Shared bobcat for basic road construction
                            </ListItem>
                        </List>
                    </Box>

                    <Box>
                        <Heading as="h2" size="xl" mb={4}>Our Vision</Heading>
                        <Text fontSize="lg">
                            We're creating a community of like-minded individuals interested in sustainable living, connection with nature, and collaborative land stewardship. This simulation will help us build a network and refine our approach to future real purchases.
                        </Text>
                    </Box>

                    <Box>
                        {/* <Heading as="h2" size="xl" mb={4}>Make Your Pledge</Heading> */}

                        {user ? (
                            <>
                                <Text fontSize="lg" mb={4}>
                                    By agreeing to participate in this simulation, you're pledging to contribute $40,000 AUD towards the land purchase. This is a simulated pledge to work through meeting people, generating new governance structures, and understanding these governance structures.
                                </Text>
                                <Checkbox
                                    isChecked={agreeToTerms}
                                    onChange={(e) => setAgreeToTerms(e.target.checked)}
                                    mb={4}
                                >
                                    I agree to pledge $40,000 AUD as part of this simulation
                                </Checkbox>
                                <Textarea
                                    mt={4}
                                    placeholder="Additional comments, skills, or ideas for the land..."
                                    value={comments}
                                    onChange={(e) => setComments(e.target.value)}
                                />
                                <Center>
                                    <Button colorScheme="blue" size="lg" mt={4} onClick={handlePledgeSubmit} isDisabled={!agreeToTerms}>
                                        Submit "Simulated" Expression of Interest
                                    </Button>
                                </Center>


                            </>
                        ) : (
                            <Center>
                                <Button colorScheme="blue" size="lg" onClick={handleLearnMore}>
                                    Learn More About This Simulation Offer
                                </Button>
                            </Center>
                        )}
                        <Spacer></Spacer>
                        <Center>
                            <Box maxWidth="300px" textAlign="center">
                                <Text fontSize="sm" fontWeight="bold" mt={4}>
                                    Coming soon: FAQ Guide. Discover how our simulation helps those accustomed to traditional governance adapt to a old mutal agreements underpinned by new digital P2P and blockchain technologies to enable self-governing systems.
                                </Text>
                            </Box>
                        </Center>
                    </Box>
                </VStack>
            </Container>

            <Modal isOpen={isModalOpen} onClose={handleModalClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Land Purchase Pledge Submitted</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Thank you for your interest in our land purchase simulation!</Text>
                        <Text mt={2}>Pledge ID: {pledgeId}</Text>
                        <Text mt={4}>We've recorded your pledge of ${pledgeAmount.toFixed(2)} AUD and your additional comments.</Text>
                        <Text mt={2}>We'll be in touch soon with more information about next steps.</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleModalClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>

            <FooterWithFourColumns />
        </Box>
    );
};

export default LandPurchaseCanungra;