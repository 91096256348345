// src/pages/MyBTCPurchases.tsx
import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from '@apollo/client';
import {
    Box,
    Container,
    Heading,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Td,
    Spinner,
    Center,
    Text,
} from '@chakra-ui/react';
import { BuyBitcoinPurchaseSchema } from '../types';
import { useAuth } from '../contexts/AuthContext';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';

const GET_BUY_BITCOIN_PURCHASES_BY_USER = gql`
    query GetBuyBitcoinPurchasesByUser($userEmail: String!) {
        getBuyBitcoinPurchasesByUser(userEmail: $userEmail) {
            id
            amountAUD
            amountBTC
            status
            createdAt
            updatedAt
            walletAddress
        }
    }
`;

export const MyBTCPurchases = () => {
    const { user } = useAuth();
    const { loading, error, data } = useQuery(GET_BUY_BITCOIN_PURCHASES_BY_USER, {
        variables: { userEmail: user?.email },
        skip: !user?.email,
    });

    if (loading) {
        return (
            <Center height="100vh">
                <Spinner size="xl" />
            </Center>
        );
    }

    if (error) {
        return (
            <Center height="100vh">
                <Text>Error: {error.message}</Text>
            </Center>
        );
    }

    const purchases = data?.getBuyBitcoinPurchasesByUser || [];

    return (
        <>
            <NavbarWithCallToAction />
            <Container maxW="container.lg" py={8}>
                <Heading as="h1" size="xl" mb={4}>
                    My Bitcoin Purchases
                </Heading>
                {purchases.length > 0 ? (
                    <Box borderWidth={1} borderRadius="md" p={4}>
                        <Table variant="simple">
                            <Thead>
                                <Tr>
                                    <Th>Amount AUD</Th>
                                    <Th>Amount BTC</Th>
                                    <Th>Status</Th>
                                    <Th>Created At</Th>
                                    <Th>Updated At</Th>
                                    <Th>Wallet Address</Th>
                                </Tr>
                            </Thead>
                            <Tbody>
                                {purchases.map((purchase: BuyBitcoinPurchaseSchema) => (
                                    <Tr key={purchase.id}>
                                        <Td>{purchase.amountAUD}</Td>
                                        <Td>{purchase.amountBTC}</Td>
                                        <Td>{purchase.status}</Td>
                                        <Td>{purchase.createdAt}</Td>
                                        <Td>{purchase.updatedAt}</Td>
                                        <Td>{purchase.walletAddress}</Td>
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                ) : (
                    <Text>No purchases found.</Text>
                )}
            </Container>
            <FooterWithFourColumns />
        </>
    );
};

export default MyBTCPurchases;