import React from 'react';
import { Button, Drawer, DrawerBody, DrawerContent, DrawerProps, Heading, Spacer, Stack, useDisclosure } from '@chakra-ui/react';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { QuoteModal } from '../../QuoteModal';

export const MobileDrawer = (props: Omit<DrawerProps, 'children'>) => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();
  const { isOpen: isQuoteModalOpen, onOpen: onQuoteModalOpen, onClose: onQuoteModalClose } = useDisclosure();


  const handleBuyBitcoinClick = () => {
    if (user) {
      navigate('/buybitcoin');
    } else {
      navigate('/login');
    }
    props.onClose();
  };

  const handleProfileClick = () => {
    if (user) {
      navigate('/profiledetails');
    } else {
      navigate('/login');
    }
    props.onClose();
  };

  const handleMarketPlaceClick = () => {
    if (user) {
      navigate('/marketplace');
    } else {
      navigate('/login');
    }
    props.onClose();
  };

  const handleEarnClick = () => {
    if (user) {
      navigate('/earn');
    } else {
      navigate('/login');
    }
    props.onClose();
  };

  const handleAuthAction = async () => {
    if (user) {
      await logout();
      navigate('/');
    } else {
      onQuoteModalOpen();
    }
    // props.onClose();
  };

  const handleWalletClick = () => {
    if (user) {
      navigate('/wallet');
    } else {
      navigate('/login');
    }
    props.onClose();
  };

  return (<>

    <Drawer placement="top" {...props}>

      <DrawerContent>
        <DrawerBody mt="16">

          <Stack spacing="6" align="stretch">
            <Spacer></Spacer>
            <Spacer></Spacer>
            {/* <Button onClick={handleBuyBitcoinClick} size="lg" variant="text" colorScheme="gray">
              Buy Bitcoin
            </Button>
            <Button onClick={handleMarketPlaceClick} size="lg" variant="text" colorScheme="gray">
              Marketplace
            </Button>
            <Button onClick={handleEarnClick} size="lg" variant="text" colorScheme="gray">
              Earn
            </Button>
            <Button onClick={handleProfileClick} size="lg" variant="text" colorScheme="gray">
              Profile
            </Button>
            <Button onClick={handleWalletClick} size="lg" variant="text" colorScheme="gray">
              Wallet
            </Button> */}

            <Button onClick={handleAuthAction}>
              {user ? 'Logout' : 'Get Quote'}
            </Button>
          </Stack>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
    <QuoteModal isOpen={isQuoteModalOpen} onClose={() => {
      onQuoteModalClose();
      props.onClose(); // Ensure drawer is closed when modal is closed
    }} />
  </>
  );
};