// src/pages/BuyBitcoin.tsx
import React, { useState, useEffect } from 'react';
import {
    Box,
    Container,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    Text,
    useToast,
    Flex,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import { useAuth } from '../contexts/AuthContext';
import { NavbarWithCallToAction } from '../components/chakra/NavbarWithCallToAction/NavbarWithCallToAction';
import { FooterWithFourColumns } from '../components/chakra/FooterWithFourColumns/FooterWithFourColumns';
import { useMutation } from '@apollo/client';
import { gql } from '@apollo/client';
import { useNavigate } from 'react-router-dom';

const CREATE_BUY_BITCOIN_PURCHASE = gql`
    mutation CreateBuyBitcoinPurchase($userEmail: String!, $amountAUD: Float!, $walletAddress: String!) {
        createBuyBitcoinPurchase(userEmail: $userEmail, amountAUD: $amountAUD, walletAddress: $walletAddress) {
            id
        }
    }
`;

const SEND_NOTIFICATION_EMAIL = gql`
    mutation SendNotificationEmail($to: String!, $subject: String!, $text: String!, $html: String!) {
        sendEmail(to: $to, subject: $subject, text: $text, html: $html)
    }
`;

export const BuyBitcoin = () => {
    const { user } = useAuth();
    const [amountAUD, setAmountAUD] = useState(0);
    const [amountBTC, setAmountBTC] = useState(0);
    const [walletAddress, setWalletAddress] = useState('');
    const toast = useToast();
    const navigate = useNavigate();

    const [createBuyBitcoinPurchase] = useMutation(CREATE_BUY_BITCOIN_PURCHASE);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [purchaseId, setPurchaseId] = useState('');

    const EXCHANGE_RATE = 79821.14;


    useEffect(() => {
        const btcAmount = amountAUD / EXCHANGE_RATE;
        setAmountBTC(btcAmount);
    }, [amountAUD]);


    // useEffect(() => {
    //     // Fetch the current BTC to AUD exchange rate and calculate the BTC amount
    //     const fetchExchangeRate = async () => {
    //         try {
    //             const response = await fetch('https://api.example.com/btc-aud-exchange-rate');
    //             const data = await response.json();
    //             const exchangeRate = data.rate;
    //             const btcAmount = amountAUD / exchangeRate;
    //             setAmountBTC(btcAmount);
    //         } catch (error) {
    //             console.error('Error fetching exchange rate:', error);
    //         }
    //     };

    //     if (amountAUD > 0) {
    //         fetchExchangeRate();
    //     }
    // }, [amountAUD]);

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (!user) {
            toast({
                title: 'Not logged in',
                description: 'Please log in to make a purchase.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        if (!user.email) {
            toast({
                title: 'Email not available',
                description: 'Please ensure your email is available in your account.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
            return;
        }

        try {
            const result = await createBuyBitcoinPurchase({
                variables: {
                    userEmail: user.email,
                    amountAUD,
                    walletAddress,
                },
            });

            setPurchaseId(result.data.createBuyBitcoinPurchase.id);
            setIsModalOpen(true);

            toast({
                title: 'Purchase submitted',
                description: 'Your Bitcoin purchase has been submitted successfully.',
                status: 'success',
                duration: 3000,
                isClosable: true,
            });

            setAmountAUD(0);
            setAmountBTC(0);
            setWalletAddress('');
            // navigate('/mybtcpurchases');
        } catch (error) {
            console.error('Error submitting purchase:', error);
            toast({
                title: 'Purchase failed',
                description: 'An error occurred while submitting your purchase.',
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    };

    const handleModalClose = () => {
        setIsModalOpen(false);
        navigate('/mybtcpurchases');
    };

    return (
        <Flex direction="column" minHeight="100vh">
            <NavbarWithCallToAction />
            <Container maxW="container.md" py={8} flex={1}>
                <Heading as="h1" size="xl" mb={4}>
                    Buy Bitcoin
                </Heading>
                <Box borderWidth={1} borderRadius="md" p={4}>
                    <form onSubmit={handleSubmit}>
                        <FormControl id="amountAUD" mb={4}>
                            <FormLabel>Amount in AUD</FormLabel>
                            <Input
                                type="number"
                                value={amountAUD}
                                onChange={(e) => setAmountAUD(parseFloat(e.target.value))}
                            />
                        </FormControl>
                        <FormControl id="amountBTC" mb={4}>
                            <FormLabel>Estimated BTC</FormLabel>
                            <Input type="number" value={amountBTC} readOnly />
                        </FormControl>
                        <FormControl id="walletAddress" mb={4}>
                            <FormLabel>Wallet Address</FormLabel>
                            <Input
                                type="text"
                                value={walletAddress}
                                onChange={(e) => setWalletAddress(e.target.value)}
                            />
                        </FormControl>
                        <Button type="submit" colorScheme="blue">
                            Buy Bitcoin
                        </Button>
                    </form>
                </Box>
            </Container>
            <FooterWithFourColumns />
            <Modal isOpen={isModalOpen} onClose={handleModalClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Bitcoin Purchase Submitted</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Your purchase has been submitted successfully.</Text>
                        <Text>Purchase ID: {purchaseId}</Text>
                        <Text mt={4}>To complete your purchase, please transfer the funds to:</Text>
                        <Text>BSB: 123-456</Text>
                        <Text>Account Number: 12345678</Text>
                        <Text>Reference: BTC-{purchaseId}</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={handleModalClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

export default BuyBitcoin;